<template>
  <div
    class="d-flex flex-wrap gap-1 justify-content-start"
    :class="statusClasses"
  >
    <div
      class="d-flex flex-wrap justify-content-center align-content-center gap-2 visitor-status-pill"
      :class="pillClasses"
      @click.stop="onStatusClick"
    >
      <i class="visitor-status-icon" :class="iconStyleClasses"></i>
      <span class="mt-3px text-capitalize">
        {{ data.isLoading ? "Loading..." : visitor.visit_status }}
      </span>
    </div>

    <template v-if="showInfoIcon">
      <i
        v-if="isBadgePrintingFailed"
        :title="statusTooltip"
        class="m-auto"
        :class="badgePrintingFailedIconClasses"
      ></i>

      <VMenu
        v-else
        class="d-flex justify-content-center align-items-center flex-grow-1"
      >
        <i :class="tooltipIconClasses"></i>

        <template #popper>
          <VisitorEntryLog :visitor="visitor" />
        </template>
      </VMenu>
    </template>
  </div>
</template>

<script>
import useDeniedVisitorFlow from "@/composables/visitor/useDeniedVisitorFlow"
import visitorConstants from "@/constants/visitorConstants"
import VisitorEntryLog from "@/v3components/Datatables/Visitor/CheckIns/VisitorEntryLog.vue"
import { computed, inject } from "vue"

export default {
  name: "VisitorStatusColumn",
  components: {
    VisitorEntryLog
  },
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const modal = inject("modal")
    const { data, startProcess } = useDeniedVisitorFlow(props.visitor, modal)

    const isDenied = computed(
      () => props?.visitor?.visit_status?.toLowerCase() === "denied"
    )

    const statusClasses = computed(() => {
      const classMap = {
        denied: "visitor-status-red",
        "signed in": "visitor-status-green",
        "signed out": "visitor-status-blue"
      }
      return (
        classMap?.[props?.visitor?.visit_status?.toLowerCase()] ||
        "visitor-status-default"
      )
    })

    const iconStyleClasses = computed(() => {
      const visitStatus = props?.visitor?.visit_status?.toLowerCase()
      return {
        "signed-in ri-check-line": visitStatus === "signed in",
        "signed-out ri-check-line": visitStatus === "signed out",
        "denied ri-close-line": visitStatus === "denied"
      }
    })

    const showInfoIcon = computed(() => {
      return (
        props?.visitor?.signin_exception_message?.toString()?.trim()?.length >
          0 || deniedLogMessages?.value?.length > 0
      )
    })

    const isBadgePrintingFailed = computed(
      () =>
        props?.visitor?.signin_exception_message
          ?.toString()
          ?.trim()
          ?.toLowerCase() === "app_badge_printing_failed"
    )

    const deniedLogMessages = computed(() => {
      const parsedLog = JSON.parse(props?.visitor?.denied_log) || {}
      if (Object.keys(parsedLog).length === 0) return ""

      const messages = []
      const logKeys = visitorConstants.DENIED_LOG_MESSAGE_KEYS
      const flowKeys = visitorConstants.DENIED_FLOW_KEYS

      Object.keys(logKeys).forEach((key) => {
        const logMessage = parsedLog?.[logKeys?.[key] || ""]?.toString()?.trim()
        if (logMessage?.length > 0) {
          messages.push(`${flowKeys[key]}: ${logMessage}`)
        }
      })

      return messages.join(" | ") || ""
    })

    const statusTooltip = computed(() => {
      if (isBadgePrintingFailed.value) return "Badge printing failed"
      return (
        deniedLogMessages.value ||
        props?.visitor?.signin_exception_message ||
        ""
      )
    })

    const onStatusClick = async () => {
      if (data.isLoading || !isDenied.value) return
      await startProcess()
    }

    const pillClasses = computed(() => ({
      "cursor-pointer bg-red-500": isDenied.value
    }))

    const tooltipIconClasses = computed(() => ({
      "ri-information-line": !isBadgePrintingFailed.value,
      "ri-printer-line visitor-status-red": isBadgePrintingFailed.value
    }))

    const badgePrintingFailedIconClasses = computed(() => ({
      "ri-printer-line visitor-status-red": isBadgePrintingFailed.value
    }))

    return {
      data,
      onStatusClick,
      isDenied,
      iconStyleClasses,
      statusClasses,
      statusTooltip,
      showInfoIcon,
      isBadgePrintingFailed,
      pillClasses,
      tooltipIconClasses,
      badgePrintingFailedIconClasses
    }
  }
}
</script>

<style scoped>
.mt-3px {
  margin-top: 3px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
