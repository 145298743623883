<template>
  <button
    :class="[classes, 'v3-sidebar-toggler']"
    type="button"
    role="button"
    aria-label="Toggle Sidebar"
  >
    <i
      :class="active ? 'ri-arrow-left-s-line' : 'ri-arrow-right-s-line'"
      aria-hidden="true"
    ></i>
  </button>
</template>

<script>
export default {
  props: { classes: [Array, String], active: Boolean }
}
</script>
