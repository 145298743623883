<template>
  <div
    v-if="!isInitialLoading"
    :key="data?.form?.formTimestamp"
    class="d-flex flex-column justify-content-center align-content-center gap-4"
  >
    <!-- FIREBASE ERROR -->
    <VisitorFirebaseErrorHandler
      v-if="firebaseError != null"
      keep-alive
      :error="firebaseError"
      @done="firebaseError = null"
    />
    <!-- FIREBASE ERROR -->

    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      whitespace
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- FIREBASE NOTIFIER -->
    <VisitorFirebaseNotifier
      v-if="firebaseMessage != ''"
      :message="firebaseMessage"
      @done="firebaseMessage = ''"
    />
    <!-- FIREBASE NOTIFIER -->

    <div
      v-if="[1, 2].includes(step)"
      class="d-flex flex-column flex-grow-1 justify-content-center align-content-center gap-4 w-100"
      :class="{
        'visitor-loading':
          isLoading || data?.form?.isLoading || isCheckInProcessInProgress
      }"
    >
      <!-- STEP 1 -->
      <VisitorDetailForm
        v-if="step === 1"
        :form="data?.form"
        :is-clear-all-disabled="isClearAllDisabled"
        :on-form-submit="onDetailFormSubmit"
        @on-form-reset="onFormReset"
        @on-notify="onNotifyApp($event)"
      />
      <!-- STEP 1 -->

      <!-- STEP 2 -->
      <VisitorHealthForm
        v-if="step === 2"
        :form="data?.form"
        :on-form-submit="onHealthFormSubmit"
      />
      <!-- STEP 2 -->
    </div>

    <!-- STEP 3 -->
    <VisitorPrintForm
      v-else
      :form="data?.form"
      @on-reset-form="onFormReset"
      @on-brother-printer-print="onBrotherPrinterPrint"
    />
    <!-- STEP 3 -->
  </div>
  <div v-else class="d-flex justify-content-center align-content-center gap-2">
    <CSpinner color="primary" class="my-auto" />
  </div>
</template>

<script>
import visitorConstants from "@/constants/visitorConstants.js"
import passHelpers from "@/helpers"
import getFcmService from "@/helpers/fcmService.js"
import { generateMarkupAndStartPrint } from "@/helpers/printerService.js"
import visitorUtils from "@/helpers/visitorUtils"
import VisitorDetailForm from "@/v3components/Forms/Visitor/CheckIns/VisitorDetailForm.vue"
import VisitorFirebaseErrorHandler from "@/v3components/Forms/Visitor/CheckIns/VisitorFirebaseErrorHandler.vue"
import VisitorFirebaseNotifier from "@/v3components/Forms/Visitor/CheckIns/VisitorFirebaseNotifier.vue"
import VisitorHealthForm from "@/v3components/Forms/Visitor/CheckIns/VisitorHealthForm.vue"
import VisitorPrintForm from "@/v3components/Forms/Visitor/CheckIns/VisitorPrintForm.vue"
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import { cloneDeep, isEqual } from "lodash"
import moment from "moment-timezone"
import {
  computed,
  defineAsyncComponent,
  inject,
  onMounted,
  reactive,
  ref
} from "vue"
import { useStore } from "vuex"

const staticData = {
  emergency: {
    settingId: visitorConstants.SETTINGS.EMERGENCY,
    alertType: visitorConstants.ALERTS.EMERGENCY
  },
  offender: {
    settingId: visitorConstants.SETTINGS.SEX_OFFENDER,
    alertType: visitorConstants.ALERTS.SEX_OFFENDER
  },
  watchlist: {
    settingId: visitorConstants.SETTINGS.WATCHLIST,
    alertType: visitorConstants.ALERTS.WATCHLIST
  }
}

const VisitorAllowDenyPopUp = defineAsyncComponent(
  () =>
    import("@/v3components/Forms/Visitor/CheckIns/VisitorAllowDenyPopUp.vue")
)

const VisitorExceptionReasonPopUp = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/CheckIns/VisitorExceptionReasonPopUp.vue"
    )
)

const VisitorMatchPopUp = defineAsyncComponent(
  () => import("@/v3components/Forms/Visitor/CheckIns/VisitorMatchPopUp.vue")
)

const VisitorSendAlertPopUp = defineAsyncComponent(
  () =>
    import("@/v3components/Forms/Visitor/CheckIns/VisitorSendAlertPopUp.vue")
)

const VisitorPrintPreviewModal = defineAsyncComponent(
  () =>
    import("@/v3components/shared/VisitorPrint/VisitorPrintPreviewModal.vue")
)

const filterPayload = (payload) => {
  return Object.fromEntries(
    Object.entries(payload).filter(([, value]) => {
      if (value === null || value === "") return false
      if (Array.isArray(value) && value.length === 0) return false
      return true
    })
  )
}

export default {
  name: "VisitorCheckInForm",
  components: {
    VisitorDetailForm,
    VisitorHealthForm,
    VisitorPrintForm,
    VisitorErrorHandler,
    VisitorFirebaseNotifier,
    VisitorFirebaseErrorHandler
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")

    const step = ref(1)
    const serverError = ref(null)
    const firebaseError = ref(null)
    const firebaseMessage = ref("")
    const isInitialLoading = ref(true)
    const isCheckInProcessInProgress = ref(false)
    const isLoading = ref(false)

    const getInitialFormData = () => ({
      formTimestamp: new Date().getTime(),
      isLoading: false,
      visitor: null,
      isVisitorAlreadyDenied: false,
      isVisitorPreApproved: false,
      reasonForVisit: null,
      lookUp: null,
      otherReason: "",
      destination: null,
      otherDestination: "",
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      profileImage: "",
      imageData: null,
      phoneNumber: "",
      students: [],
      questions: [],
      exceptionMessage: "",
      matchId: null,
      matchStatus: 0,
      matchType: "",
      sourcedId: "",
      addMatchStatusDeniedFlag: false,
      matchStatusDeniedFlag: false,
      addHealthScreeningDeniedFlag: false,
      healthScreeningDeniedFlag: false,
      fromWatchlistOrOffenderFlag: false,
      deniedLog: {
        pre_approved: false,
        pre_approved_reason: "",
        watchlist: false,
        watchlist_reason: "",
        offender: false,
        offender_reason: "",
        health_screening: false,
        health_screening_qna: [],
        health_screening_reason: "",
        non_guardian: false,
        non_guardian_student: [],
        non_guardian_reason: ""
      }
    })

    const data = reactive({
      form: getInitialFormData()
    })

    const initialFormData = ref(null)

    const isClearAllDisabled = computed(() => {
      const dataFormWithoutTimestamp = cloneDeep({ ...data.form })
      delete dataFormWithoutTimestamp.formTimestamp
      const initialFormDataWithoutTimestamp = cloneDeep({
        ...initialFormData.value
      })
      delete initialFormDataWithoutTimestamp.formTimestamp
      return isEqual(dataFormWithoutTimestamp, initialFormDataWithoutTimestamp)
    })

    const school = computed(() => store.getters["visitorSettings/school"])

    const activeSchool = computed(
      () => store?.getters?.["schools/activeSchool"] || null
    )

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const userDetail = computed(
      () => store?.getters["authentication/user"] || null
    )

    const currentMatch = computed(
      () => store?.getters?.["visitorManage/matchType"] || "emergency"
    )

    const capitalizeCurrentMatch = computed(() => {
      return currentMatch.value
        ? currentMatch.value.charAt(0).toUpperCase() +
            currentMatch.value.slice(1)
        : ""
    })

    const isAlertSettingActive = computed(() => {
      return visitorUtils.forSettings.isSettingsEnabled(
        school.value,
        staticData?.[currentMatch.value]?.settingId || ""
      )
    })

    const isHealthScreeningEnabled = computed(() => {
      return visitorUtils.forSettings.isSettingsEnabled(
        school.value,
        visitorConstants.SETTINGS.HEALTH_SCREENING
      )
    })

    const isPreApprovedVisitorEnabled = computed(() => {
      return visitorUtils.forSettings.isSettingsEnabled(
        school.value,
        visitorConstants.SETTINGS.PRE_APPROVED_VISITOR
      )
    })

    const isDymoSelfBadgePrintingEnabled = computed(() => {
      return visitorUtils.forSettings.isSettingsEnabled(
        school.value,
        visitorConstants.SETTINGS.SELF_BADGE_PRINT
      )
    })

    const isDymoPrinterAddedOnPrinterSetup = computed(
      () => store.getters["visitorManage/hasDymoPrinter"]
    )

    const isDestinationSettingEnabled = computed(() => {
      return visitorUtils.forSettings.isSettingsEnabled(
        school.value,
        visitorConstants.SETTINGS.DESTINATION
      )
    })

    const healthScreeningQuestionList = computed(() => {
      return (
        visitorSchoolData.value?.health_screening_questions
          ?.filter((question) => Number(question?.active) === 1)
          ?.filter((question) => {
            const hasEnglishText = question?.question?.trim()?.length > 0
            const hasAnswer = [0, 1].includes(Number(question?.correct_answer))
            return hasEnglishText && hasAnswer
          }) || []
      )
    })

    const isStudentPickUpOrDropOffReasonSelected = computed(() => {
      return (
        ["PICK_UP", "DROP_OFF"].includes(
          data?.form?.reasonForVisit?.value?.lookup_code
            ?.trim()
            ?.toUpperCase() || ""
        ) || false
      )
    })

    const areAllVisitorAnswerCorrect = computed(() => {
      return (
        data?.form?.questions?.every((question) => {
          const yes = question.isVisitorAnsweredYes ? 1 : 0
          return yes === Number(question.correct_answer)
        }) || false
      )
    })

    /**
     * When health screening setting is enabled,
     * then the visitor will be shown the health screening form every time
     */
    const shouldGoForHealthCheck = computed(() => {
      return isHealthScreeningEnabled.value
    })

    const setCurrentMatchType = (type = "emergency") => {
      store.commit(
        "visitorManage/SET_MATCH_TYPE",
        type?.trim()?.toLowerCase() || "emergency"
      )
    }

    const showHealthCheckForm = () => {
      step.value = 2
      isCheckInProcessInProgress.value = false
    }

    const showPrintForm = () => {
      step.value = 3
      isCheckInProcessInProgress.value = false
    }

    const stopCheckInProcess = () => {
      isCheckInProcessInProgress.value = false
    }

    const onHealthFormSubmit = async (healthForm) => {
      isCheckInProcessInProgress.value = true

      data.form = {
        ...data.form,
        ...healthForm
      }

      /**
       * When the health screening is enabled and
       * the visitor does not answer all the questions correctly
       */
      if (!areAllVisitorAnswerCorrect.value) {
        return showHealthCheckFailedAllowDenyForm()
      }

      prepareDeniedLogPayload({
        health_screening: false,
        health_screening_qna:
          data?.form?.questions?.map((question) => ({
            question: question?.question || "",
            answer: question?.isVisitorAnsweredYes,
            is_correct:
              Number(question?.isVisitorAnsweredYes) ===
              question?.correct_answer
          })) || [],
        health_screening_reason:
          visitorConstants.CHECK_IN_FORM.ALL_HEALTH_CHECK_QUESTIONS_CORRECT
      })

      const { success } = await checkIn({
        match_id: data?.form?.matchId || null,
        match_status: data?.form?.matchStatus || 0,
        match_type: data?.form?.matchType || "",
        add_health_screening_denied_flag: true,
        health_screening_denied_flag: false
      })

      if (!success) {
        return stopCheckInProcess()
      }
      return showPrintForm()
    }

    const denyHealthCheckFailedVisitor = async () => {
      data.form.matchStatus = 1
      data.form.matchType =
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_HEALTH_CHECK_FAILED
      data.form.exceptionMessage =
        visitorConstants.CHECK_IN_FORM.EXCEPTION_MESSAGE_HEALTH_CHECK_FAILED
      prepareDeniedLogPayload({
        health_screening: true,
        health_screening_qna:
          data?.form?.questions?.map((question) => ({
            question: question?.question || "",
            answer: question?.isVisitorAnsweredYes,
            is_correct:
              Number(question?.isVisitorAnsweredYes) ===
              Number(question?.correct_answer)
          })) || [],
        health_screening_reason:
          visitorConstants.CHECK_IN_FORM.EXCEPTION_MESSAGE_HEALTH_CHECK_FAILED
      })

      const { success } = await checkIn({
        visit_status: visitorConstants.CHECK_IN_FORM.VISIT_STATUS_DENIED,
        match_status: 1,
        match_type:
          visitorConstants.CHECK_IN_FORM.MATCH_TYPE_HEALTH_CHECK_FAILED,
        exception_message:
          visitorConstants.CHECK_IN_FORM.EXCEPTION_MESSAGE_HEALTH_CHECK_FAILED,
        add_health_screening_denied_flag: true,
        health_screening_denied_flag: true
      })
      if (!success) {
        return stopCheckInProcess()
      }

      return onFormReset()
    }

    const showHealthCheckFailedAllowDenyForm = () => {
      modal.open(VisitorAllowDenyPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: "health-check",
          allow: () => {
            return showHealthCheckFailedExceptionReasonForm()
          },
          deny: async () => {
            return await denyHealthCheckFailedVisitor()
          }
        }
      })
    }

    const showHealthCheckFailedExceptionReasonForm = () => {
      modal.open(VisitorExceptionReasonPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: "health-check",
          signIn: async (reason) => {
            data.form.exceptionMessage = reason
            data.form.matchStatus = 1
            data.form.matchType =
              visitorConstants.CHECK_IN_FORM.MATCH_TYPE_HEALTH_CHECK_FAILED
            prepareDeniedLogPayload({
              health_screening: true,
              health_screening_qna:
                data?.form?.questions?.map((question) => ({
                  question: question?.question || "",
                  answer: question?.isVisitorAnsweredYes,
                  is_correct:
                    Number(question?.isVisitorAnsweredYes) ===
                    Number(question?.correct_answer)
                })) || [],
              health_screening_reason: reason
            })

            const { success } = await checkIn({
              visit_status:
                visitorConstants.CHECK_IN_FORM.VISIT_STATUS_SIGNED_IN,
              match_status: 1,
              match_type:
                visitorConstants.CHECK_IN_FORM.MATCH_TYPE_HEALTH_CHECK_FAILED,
              exception_message: reason,
              add_health_screening_denied_flag: true,
              health_screening_denied_flag: false
            })
            if (!success) {
              return stopCheckInProcess()
            }

            return showPrintForm()
          },
          deny: async () => {
            return await denyHealthCheckFailedVisitor()
          }
        }
      })
    }

    const onDetailFormSubmit = async (detailForm) => {
      setCurrentMatchType()
      isCheckInProcessInProgress.value = true

      data.form = {
        ...data.form,
        ...detailForm
      }

      const addVisitorPayload = prepareAddVisitorPayload()
      const { result: addVisitorResult, success: addVisitorSuccess } =
        await addVisitor(addVisitorPayload)

      if (!addVisitorSuccess) {
        return stopCheckInProcess()
      }

      data.form.visitor = addVisitorResult || null

      const checkPreApprovedVisitorPayload =
        prepareCheckPreApprovedVisitorPayload()
      const { result: preApprovedResult, success: preApprovedSuccess } =
        isPreApprovedVisitorEnabled?.value
          ? await checkPreApprovedVisitor(checkPreApprovedVisitorPayload)
          : { result: true, success: true }

      if (!preApprovedSuccess) {
        return stopCheckInProcess()
      }

      data.form.isVisitorPreApproved = preApprovedResult

      /**
       * When the visitor is pre-approved and health screening is enabled
       * then the visitor will directly go to the health screening form
       */
      if (
        isPreApprovedVisitorEnabled.value &&
        data?.form?.isVisitorPreApproved &&
        shouldGoForHealthCheck.value
      ) {
        return showHealthCheckForm()
      }

      /**
       * When the visitor is not in the pre-approved list,
       * then the visitor will be shown the allow/deny form
       */
      if (
        isPreApprovedVisitorEnabled.value &&
        !data?.form?.isVisitorPreApproved
      ) {
        return showNonPreApprovedVisitorAllowDenyForm()
      }

      /**
       * When the visitor is in the watchlist or offender list,
       * then the visitor will be shown the allow/deny form
       */
      if (Number(data?.form?.visitor?.validate_status) === 1) {
        return showWatchlistOffenderMatchForm()
      }

      const { success: checkInSuccess } = await checkIn({
        match_id: data?.form?.matchId || null,
        match_status: data?.form?.matchStatus || 0,
        match_type: data?.form?.matchType || ""
      })

      if (!checkInSuccess) {
        return stopCheckInProcess()
      }

      return showPrintForm()
    }

    const prepareAddVisitorPayload = () => {
      const payload = {
        first_name: data?.form?.firstName || "",
        last_name: data?.form?.lastName || "",
        date_of_birth:
          passHelpers.currTzDate(data?.form?.dateOfBirth, "MM/DD/YYYY") || "",
        destination: data?.form?.destination?.value?.destination || "",
        phone_number: data?.form?.phoneNumber || null,
        reason_type_id: data?.form?.reasonForVisit?.value?.id || "",
        reasonForVisitTardy: data?.form?.lookUp?.value?.id || null,
        reasonForVisitTextTardy:
          data?.form?.lookUp?.value?.lookup_value || null,
        profile_image: data?.form?.imageData || null,
        check_pre_approved_list: isPreApprovedVisitorEnabled.value || false,
        students:
          data?.form?.students?.map((std) => ({
            id: Number(std?.student_id) || "",
            first_name: std?.first_name || "",
            last_name: std?.last_name || ""
          })) || [],
        sourcedid: data?.form?.sourcedId || null
      }
      return filterPayload(payload)
    }

    const addVisitor = async (payload) => {
      try {
        data.form.isLoading = true
        const result = await store.dispatch("visitorManage/addVisitor", payload)
        return {
          result,
          success: true
        }
      } catch (error) {
        serverError.value = error
        return {
          success: false,
          result: null
        }
      } finally {
        data.form.isLoading = false
      }
    }

    const prepareCheckPreApprovedVisitorPayload = () => {
      return visitorUtils.forPayload.preApprovedCheckPayload({
        first_name: data?.form?.firstName || "",
        last_name: data?.form?.lastName || "",
        profile: {
          date_of_birth:
            passHelpers.currTzDate(data?.form?.dateOfBirth, "YYYY-MM-DD") || ""
        }
      })
    }

    const checkPreApprovedVisitor = async (payload) => {
      try {
        data.form.isLoading = true
        const { result, success } =
          await visitorUtils.forChecks.checkPreApproved(payload)
        if (!success) {
          throw new Error(result?.error || "")
        }
        return { result, success: true }
      } catch (error) {
        serverError.value = error
        return { result: null, success: false }
      } finally {
        data.form.isLoading = false
      }
    }

    const denyNonPreApporvedVisitor = async () => {
      data.form.isVisitorAlreadyDenied = true
      data.form.matchStatus = 1
      data.form.matchType =
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_PRE_APPROVED_VISITOR
      data.form.addMatchStatusDeniedFlag = true
      data.form.matchStatusDeniedFlag = true
      data.form.exceptionMessage =
        visitorConstants.CHECK_IN_FORM.EXCEPTION_MESSAGE_NON_PRE_APPROVED_VISITOR
      prepareDeniedLogPayload({
        pre_approved: true,
        pre_approved_reason:
          visitorConstants.CHECK_IN_FORM
            .EXCEPTION_MESSAGE_NON_PRE_APPROVED_VISITOR
      })

      if (shouldGoForHealthCheck.value) {
        return showHealthCheckForm()
      }

      const { success } = await checkIn({
        visit_status: visitorConstants.CHECK_IN_FORM.VISIT_STATUS_DENIED,
        match_status: 1,
        match_type:
          visitorConstants.CHECK_IN_FORM.MATCH_TYPE_PRE_APPROVED_VISITOR,
        exception_message:
          visitorConstants.CHECK_IN_FORM
            .EXCEPTION_MESSAGE_NON_PRE_APPROVED_VISITOR,
        add_match_status_denied_flag: true,
        match_status_denied_flag: true
      })
      if (!success) {
        return stopCheckInProcess()
      }

      return onFormReset()
    }

    const showNonPreApprovedVisitorAllowDenyForm = () => {
      modal.open(VisitorAllowDenyPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: "non-pre-approved-visitor",
          allow: () => {
            return showNonPreApprovedVisitorExceptionReasonForm()
          },
          deny: async () => {
            return await denyNonPreApporvedVisitor()
          }
        }
      })
    }

    const showNonPreApprovedVisitorExceptionReasonForm = () => {
      modal.open(VisitorExceptionReasonPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: "non-pre-approved-visitor",
          signIn: async (reason) => {
            data.form.exceptionMessage = reason
            data.form.matchStatus = 1
            data.form.matchType =
              visitorConstants.CHECK_IN_FORM.MATCH_TYPE_PRE_APPROVED_VISITOR
            data.form.addMatchStatusDeniedFlag = true
            data.form.matchStatusDeniedFlag = false
            prepareDeniedLogPayload({
              pre_approved: true,
              pre_approved_reason: reason
            })

            if (shouldGoForHealthCheck.value) {
              return showHealthCheckForm()
            }

            const { success } = await checkIn({
              visit_status:
                visitorConstants.CHECK_IN_FORM.VISIT_STATUS_SIGNED_IN,
              match_status: 1,
              match_type:
                visitorConstants.CHECK_IN_FORM.MATCH_TYPE_PRE_APPROVED_VISITOR,
              exception_message: reason,
              add_match_status_denied_flag: true,
              match_status_denied_flag: false
            })
            if (!success) {
              return stopCheckInProcess()
            }

            return showPrintForm()
          },
          deny: async () => {
            return await denyNonPreApporvedVisitor()
          }
        }
      })
    }

    const prepareWatchlistOffenderCheckPayload = () => {
      return visitorUtils.forPayload.watchlistOffenderCheckPayload({
        first_name: data?.form?.visitor?.first_name,
        last_name: data?.form?.visitor?.last_name,
        profile: {
          date_of_birth: passHelpers.currTzDate(
            data?.form?.dateOfBirth,
            "YYYY-MM-DD"
          )
        }
      })
    }

    const checkOffender = async (payload) => {
      try {
        data.form.isLoading = true
        const { result, success } =
          await visitorUtils.forChecks.checkOffender(payload)
        if (!success) {
          throw new Error(result?.error || "")
        }
        return {
          result,
          success
        }
      } catch (error) {
        serverError.value = error
        return {
          result: null,
          success: false
        }
      } finally {
        data.form.isLoading = false
      }
    }

    const checkWatchlist = async (payload) => {
      try {
        data.form.isLoading = true
        const { result, success } =
          await visitorUtils.forChecks.checkWatchlist(payload)
        if (!success) {
          throw new Error(result?.error || "")
        }
        return {
          result,
          success
        }
      } catch (error) {
        serverError.value = error
        return {
          result: null,
          success: false
        }
      } finally {
        data.form.isLoading = false
      }
    }

    const showWatchlistOffenderMatchForm = async () => {
      try {
        const checkPayload = prepareWatchlistOffenderCheckPayload()

        const { result: offenderList, success: offenderSuccess } =
          await checkOffender(checkPayload)
        if (!offenderSuccess) {
          return stopCheckInProcess()
        }

        const { result: watchList, success: watchListSuccess } =
          await checkWatchlist(checkPayload)
        if (!watchListSuccess) {
          return stopCheckInProcess()
        }

        const isBoth = offenderList?.length > 0 && watchList?.length > 0
        const isOffenderType = offenderList?.length > 0 ? true : false

        const title = isBoth
          ? visitorConstants.CHECK_IN_FORM
              .MODAL_TITLE_WATCHLIST_OR_OFFENDER_MATCH
          : isOffenderType
            ? visitorConstants.CHECK_IN_FORM.MODAL_TITLE_OFFENDER_MATCH
            : visitorConstants.CHECK_IN_FORM.MODAL_TITLE_WATCHLIST_MATCH
        const description = isBoth
          ? visitorConstants.CHECK_IN_FORM
              .MODAL_DESCRIPTION_WATCHLIST_OR_OFFENDER_MATCH
          : isOffenderType
            ? visitorConstants.CHECK_IN_FORM.MODAL_DESCRIPTION_OFFENDER_MATCH
            : visitorConstants.CHECK_IN_FORM.MODAL_DESCRIPTION_WATCHLIST_MATCH

        if (offenderList.length > 0 || watchList.length > 0) {
          modal.open(VisitorMatchPopUp, {
            size: "lg",
            hideCloseButton: true,
            props: {
              type: isOffenderType
                ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
                : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST,
              title,
              description,
              visitor: {
                ...data?.form?.visitor,
                local_profile_image:
                  data?.form?.imageData || data?.form?.profileImage || ""
              },
              watchList,
              offenderList,
              match: (matchedUser) => {
                const matchType =
                  (matchedUser?.type?.toLowerCase()?.trim() || "") ===
                  visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
                    ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
                    : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST

                data.form.matchId = matchedUser?.id || null
                data.form.matchStatus = 1
                data.form.matchType = matchType

                setCurrentMatchType(matchType)
                return showVisitorWOVAllowDenyForm({
                  match_id: matchedUser?.id || null,
                  match_status: 1,
                  match_type: matchType
                })
              },
              noMatch: () => {
                return onNoWatchlistAndOffenderMatch(true)
              }
            }
          })
        } else {
          return onNoWatchlistAndOffenderMatch()
        }
      } catch (error) {
        serverError.value = error
      } finally {
        data.form.isLoading = false
      }
    }

    const denyWatchlistOffenderVisitor = async (payload, matchType) => {
      data.form.isVisitorAlreadyDenied = true
      data.form.matchId = payload.match_id
      data.form.matchStatus = 1
      data.form.matchType = matchType
      data.form.addMatchStatusDeniedFlag = true
      data.form.matchStatusDeniedFlag = true
      data.form.exceptionMessage = `${matchType} database match`

      const isWatchlist =
        matchType === visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
      prepareDeniedLogPayload(
        isWatchlist
          ? {
              watchlist: true,
              watchlist_reason: `${matchType} database match`
            }
          : {
              offender: true,
              offender_reason: `${matchType} database match`
            }
      )

      if (shouldGoForHealthCheck.value) {
        return showHealthCheckForm()
      }

      const { success } = await checkIn({
        visit_status: visitorConstants.CHECK_IN_FORM.VISIT_STATUS_DENIED,
        match_id: payload.match_id,
        match_status: payload.match_status,
        match_type: matchType,
        exception_message: `${matchType} database match`,
        add_match_status_denied_flag: true,
        match_status_denied_flag: true
      })
      if (!success) {
        return stopCheckInProcess()
      }

      return onFormReset()
    }

    const showVisitorWOVAllowDenyForm = (payload) => {
      const matchType =
        (payload?.match_type?.toLowerCase()?.trim() || "") ===
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
          ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
          : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
      modal.open(VisitorAllowDenyPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: matchType,
          allow: () => {
            return showVisitorWOVExceptionReasonForm(payload)
          },
          deny: async () => {
            return await denyWatchlistOffenderVisitor(payload, matchType)
          }
        }
      })
    }

    const showVisitorWOVExceptionReasonForm = (payload) => {
      const matchType =
        (payload?.match_type?.toLowerCase()?.trim() || "") ===
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
          ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
          : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
      modal.open(VisitorExceptionReasonPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: matchType,
          signIn: async (reason) => {
            data.form.exceptionMessage = reason
            data.form.matchId = payload.match_id
            data.form.matchStatus = 1
            data.form.matchType = matchType
            data.form.addMatchStatusDeniedFlag = true
            data.form.matchStatusDeniedFlag = false
            const isWatchlist =
              matchType === visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
            prepareDeniedLogPayload(
              isWatchlist
                ? {
                    watchlist: true,
                    watchlist_reason: reason
                  }
                : {
                    offender: true,
                    offender_reason: reason
                  }
            )

            /**
             * When the visitor is in the watchlist or offender list and
             * the visitor is allowed and wants to pick up or drop off the student,
             * then the visitor will be shown non-guardian visitor flow
             */
            if (isStudentPickUpOrDropOffReasonSelected.value) {
              return await handleStudentPickUpOrDropOffSelected(
                data.form.addMatchStatusDeniedFlag,
                true
              )
            }

            if (shouldGoForHealthCheck.value) {
              return showHealthCheckForm()
            }

            const { success } = await checkIn({
              visit_status:
                visitorConstants.CHECK_IN_FORM.VISIT_STATUS_SIGNED_IN,
              match_id: payload.match_id,
              match_status: payload.match_status,
              match_type: matchType,
              exception_message: reason,
              add_match_status_denied_flag: true,
              match_status_denied_flag: false
            })
            if (!success) {
              return stopCheckInProcess()
            }

            setCurrentMatchType(matchType)
            return showPrintForm()
          },
          deny: async () => {
            return await denyWatchlistOffenderVisitor(payload, matchType)
          }
        }
      })
    }

    const prepareNonGuardianCheckPayload = () => {
      return {
        visitorId: data?.form?.visitor?.id || "",
        firstName: data?.form?.visitor?.first_name,
        lastName: data?.form?.visitor?.last_name,
        students:
          data?.form?.visitor?.students?.map((item) => ({
            first_name: item?.first_name || "",
            last_name: item?.last_name || ""
          })) ||
          data?.form?.students?.map((item) => ({
            first_name: item?.first_name || "",
            last_name: item?.last_name || ""
          })) ||
          []
      }
    }

    const handleStudentPickUpOrDropOffSelected = async (
      addMatchStatusDeniedFlag = false,
      fromWatchlistOrOffenderFlag = false
    ) => {
      try {
        data.form.isLoading = true
        data.form.fromWatchlistOrOffenderFlag = fromWatchlistOrOffenderFlag
        const validatePayload = prepareNonGuardianCheckPayload()
        const {
          error,
          message,
          type,
          data: visitorGuardianData
        } = await visitorUtils.forChecks.checkNonGuardian(validatePayload)

        // When students details do not exist
        if (error) {
          stopCheckInProcess()
          throw new Error(message)
        }

        // When the student details exist and the visitor is a non-guardian visitor
        if (
          !error &&
          type ===
            visitorConstants.CHECK_IN_FORM.CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES
              .VALID_STUDENT_NON_GUARDIAN_DETAILS
        ) {
          return showVisitorNGVAllowDenyForm({
            students: visitorGuardianData?.students || []
          })
        }

        if (addMatchStatusDeniedFlag) {
          data.form.addMatchStatusDeniedFlag = true
          data.form.matchStatusDeniedFlag = false
        }

        if (shouldGoForHealthCheck.value) {
          return showHealthCheckForm()
        }

        const { success } = await checkIn({
          match_id: data?.form?.matchId || null,
          match_status: data?.form?.matchStatus || 0,
          match_type: data?.form?.matchType || "",
          add_match_status_denied_flag: addMatchStatusDeniedFlag,
          match_status_denied_flag: false
        })

        if (!success) {
          return stopCheckInProcess()
        }

        return showPrintForm()
      } catch (error) {
        serverError.value = error
      } finally {
        data.form.isLoading = false
      }
    }

    const handleNonStudentPickUpOrDropOffSelected = async (
      addMatchStatusDeniedFlag = false
    ) => {
      try {
        data.form.isLoading = true

        if (addMatchStatusDeniedFlag) {
          data.form.addMatchStatusDeniedFlag = true
          data.form.matchStatusDeniedFlag = false
        }

        if (shouldGoForHealthCheck.value) {
          return showHealthCheckForm()
        }

        const { success } = await checkIn({
          match_id: data?.form?.matchId || null,
          match_status: data?.form?.matchStatus || 0,
          match_type: data?.form?.matchType || "",
          add_match_status_denied_flag: addMatchStatusDeniedFlag,
          match_status_denied_flag: false
        })

        if (!success) {
          return stopCheckInProcess()
        }

        return showPrintForm()
      } catch (error) {
        serverError.value = error
      } finally {
        data.form.isLoading = false
      }
    }

    const onNoWatchlistAndOffenderMatch = async (
      addMatchStatusDeniedFlag = false
    ) => {
      if (isStudentPickUpOrDropOffReasonSelected.value) {
        await handleStudentPickUpOrDropOffSelected(addMatchStatusDeniedFlag)
      } else {
        await handleNonStudentPickUpOrDropOffSelected(addMatchStatusDeniedFlag)
      }
    }

    const denyNonGuardianVisitor = async () => {
      data.form.isVisitorAlreadyDenied = true
      data.form.matchStatus = 1
      data.form.matchType =
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_NON_GUARDIAN_VISITOR
      data.form.addMatchStatusDeniedFlag = true
      data.form.matchStatusDeniedFlag = true
      data.form.exceptionMessage =
        visitorConstants.CHECK_IN_FORM.EXCEPTION_MESSAGE_NON_GUARDIAN_VISITOR

      prepareDeniedLogPayload({
        non_guardian: true,
        non_guardian_student:
          data?.form?.students?.map(
            (std) => `${std?.first_name} ${std?.last_name}`
          ) || [],
        non_guardian_reason:
          visitorConstants.CHECK_IN_FORM.EXCEPTION_MESSAGE_NON_GUARDIAN_VISITOR
      })

      if (shouldGoForHealthCheck.value) {
        return showHealthCheckForm()
      }

      const { success } = await checkIn({
        visit_status: visitorConstants.CHECK_IN_FORM.VISIT_STATUS_DENIED,
        match_status: 1,
        match_type:
          visitorConstants.CHECK_IN_FORM.MATCH_TYPE_NON_GUARDIAN_VISITOR,
        exception_message:
          visitorConstants.CHECK_IN_FORM.EXCEPTION_MESSAGE_NON_GUARDIAN_VISITOR,
        add_match_status_denied_flag: true,
        match_status_denied_flag: true
      })
      if (!success) {
        return stopCheckInProcess()
      }

      return onFormReset()
    }

    const showVisitorNGVAllowDenyForm = (payload) => {
      modal.open(VisitorAllowDenyPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: "non-guardian-visitor",
          visitor: data?.form.visitor,
          students: payload?.students || [],
          allow: () => {
            return showNonGuardianVisitorExceptionReasonForm()
          },
          deny: async () => {
            return await denyNonGuardianVisitor()
          }
        }
      })
    }

    const showNonGuardianVisitorExceptionReasonForm = () => {
      modal.open(VisitorExceptionReasonPopUp, {
        size: "sm",
        hideCloseButton: true,
        props: {
          type: "non-guardian-visitor",
          signIn: async (reason) => {
            data.form.exceptionMessage = reason
            data.form.matchStatus = 1
            data.form.matchType =
              visitorConstants.CHECK_IN_FORM.MATCH_TYPE_NON_GUARDIAN_VISITOR
            data.form.addMatchStatusDeniedFlag = true
            data.form.matchStatusDeniedFlag = false
            prepareDeniedLogPayload({
              non_guardian: true,
              non_guardian_reason: reason,
              non_guardian_student:
                data?.form?.students?.map(
                  (std) => `${std?.first_name} ${std?.last_name}`
                ) || []
            })

            if (shouldGoForHealthCheck.value) {
              return showHealthCheckForm()
            }

            const { success } = await checkIn({
              visit_status:
                visitorConstants.CHECK_IN_FORM.VISIT_STATUS_SIGNED_IN,
              match_status: 1,
              match_type:
                visitorConstants.CHECK_IN_FORM.MATCH_TYPE_NON_GUARDIAN_VISITOR,
              exception_message: reason,
              add_match_status_denied_flag: true,
              match_status_denied_flag: false
            })
            if (!success) {
              return stopCheckInProcess()
            }

            return showPrintForm()
          },
          deny: async () => {
            return await denyNonGuardianVisitor()
          }
        }
      })
    }

    const onFormReset = () => {
      isCheckInProcessInProgress.value = false
      data.form = getInitialFormData()
      prepareHealthQuestions()
      step.value = 1
    }

    const getHealthScreeningPayload = (params) => {
      /**
       * When the health screening setting is disabled or
       * the visitor is in the first step of the check-in process
       * then the health screening payload will be empty
       */
      if (!isHealthScreeningEnabled.value || step.value === 1) {
        return { healthScreening: [], healthScreeningSuccess: null }
      }
      if (!shouldGoForHealthCheck.value) {
        return {
          healthScreening:
            data?.form?.questions?.map((question) => ({
              id: Number(question?.question_id),
              answer: question?.correct_answer || 0
            })) || [],
          healthScreeningSuccess: 1
        }
      }
      const healthScreening =
        params?.health_screening ||
        data?.form?.questions?.map((question) => ({
          id: Number(question?.question_id),
          answer: question.isVisitorAnsweredYes ? 1 : 0
        })) ||
        []
      const areAnswersCorrect = areAllVisitorAnswerCorrect.value ? 1 : 0
      const healthScreeningSuccess =
        params?.health_screening_success || areAnswersCorrect
      return {
        healthScreening,
        healthScreeningSuccess
      }
    }

    const prepareCheckInPayload = (params) => {
      const payload = {
        visitor_id: params?.visitor_id || data?.form?.visitor?.id || null,
        reason_type_id:
          params?.reason_type_id ||
          data?.form?.reasonForVisit?.value?.id ||
          null,
        reason_description:
          params?.reason_description ||
          data?.form?.reasonForVisit?.value?.lookup_value ||
          null,
        sub_reason_type_id:
          params?.sub_reason_type_id || data?.form?.lookUp?.value?.id || 0,
        sub_reason_description:
          params?.sub_reason_description ||
          data?.form?.otherReason ||
          data?.form?.lookUp?.value?.lookup_value ||
          "",
        destination_id:
          params?.destination_id || data?.form?.destination?.value?.id || null,
        destination:
          params?.destination ||
          data?.form?.destination?.value?.destination ||
          null,
        other_destination:
          params?.other_destination || data?.form?.otherDestination || "",
        match_status: params?.match_status || 0,
        match_id: params?.match_id || null,
        match_type:
          params?.match_type || data?.form?.visitor?.match_type || null,
        students:
          params?.students?.map((std) => ({
            id: Number(std?.student_id) || "",
            first_name: std?.first_name || "",
            last_name: std?.last_name || ""
          })) ||
          data?.form?.students?.map((std) => ({
            id: Number(std?.student_id) || "",
            first_name: std?.first_name || "",
            last_name: std?.last_name || ""
          })) ||
          [],
        visit_status: data?.form?.isVisitorAlreadyDenied
          ? visitorConstants.CHECK_IN_FORM.VISIT_STATUS_DENIED
          : params?.visit_status ||
            visitorConstants.CHECK_IN_FORM.VISIT_STATUS_SIGNED_IN,
        exception_message:
          params?.exception_message || data?.form?.exceptionMessage || null,
        sourcedid: params?.sourcedid || data?.form?.sourcedId || null,
        health_screening: getHealthScreeningPayload(params)?.healthScreening,
        health_screening_success:
          getHealthScreeningPayload(params)?.healthScreeningSuccess
      }
      if (
        params?.add_health_screening_denied_flag ||
        data?.form?.addHealthScreeningDeniedFlag
      ) {
        payload.health_screening_denied_flag =
          params?.health_screening_denied_flag ||
          data?.form?.healthScreeningDeniedFlag
      }
      if (
        params?.add_match_status_denied_flag ||
        data?.form?.addMatchStatusDeniedFlag
      ) {
        payload.match_status_denied_flag =
          params?.match_status_denied_flag || data?.form?.matchStatusDeniedFlag
      }
      return filterPayload(payload)
    }

    const showWatchListOffenderAlertPopUp = () => {
      const mapForArticle = {
        emergency: "an",
        offender: "an",
        watchlist: "a"
      }
      modal.open(VisitorSendAlertPopUp, {
        hideCloseButton: true,
        size: "sm",
        props: {
          isAlertActive: isAlertSettingActive.value,
          title: isAlertSettingActive.value
            ? `${capitalizeCurrentMatch.value} alert`
            : "Notification not sent",
          body: isAlertSettingActive.value
            ? `Do you want to send ${mapForArticle?.[currentMatch.value] || ""} ${currentMatch.value} alert? This cannot be undone.`
            : `Please check alert configurations. Contact your admin to update Security Alerts (settings)`,
          type: currentMatch.value,
          shouldUpdateLogs: true
        }
      })

      return {
        success: true
      }
    }

    const prepareDeniedLogPayload = (payload) => {
      const currentDeniedLog = {
        ...data?.form?.deniedLog,
        ...payload
      }
      data.form.deniedLog = currentDeniedLog
    }

    const updateVisitor = async (payload) => {
      try {
        const response = await store.dispatch(
          "visitorManage/updateVisitor",
          payload
        )
        return {
          success: true,
          response
        }
      } catch {
        return {
          success: false,
          response: null
        }
      }
    }

    const sendDeniedLog = async (visitorPayload, updateVisitorResponse) => {
      if (
        visitorPayload?.visit_status !==
        visitorConstants.CHECK_IN_FORM.VISIT_STATUS_DENIED
      ) {
        return {
          success: true
        }
      }
      try {
        const payload = {
          visit_id: updateVisitorResponse?.data?.data?.visit_id || "",
          denied_log: {
            ...data?.form?.deniedLog
          }
        }
        await store.dispatch("visitorManage/sendDeniedLog", payload)
        return {
          success: true
        }
      } catch {
        return {
          success: false
        }
      }
    }

    const checkIn = async (payload) => {
      try {
        data.form.isLoading = true
        const checkInPayload = prepareCheckInPayload(payload)
        const updateVisitorResult = await updateVisitor(checkInPayload)
        if (!updateVisitorResult.success) {
          throw new Error(
            "Shucks! Something went wrong while we were trying to check-in visitor. Please try again."
          )
        }
        const sendDeniedLogResult = await sendDeniedLog(
          checkInPayload,
          updateVisitorResult.response
        )
        if (!sendDeniedLogResult.success) {
          throw new Error(
            "Visitor checked-in successfully but something went wrong while we were sending the denied log."
          )
        }
        if (
          data?.form?.fromWatchlistOrOffenderFlag ||
          [
            visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER,
            visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
          ].includes(checkInPayload?.match_type?.toLowerCase()?.trim() || "")
        ) {
          return showWatchListOffenderAlertPopUp()
        }
        store.commit("visitorManage/SET_UPDATE_VISITOR_LOGS", true)
        return {
          success: true
        }
      } catch (error) {
        serverError.value = error
        return {
          success: false
        }
      } finally {
        data.form.isLoading = false
      }
    }

    const prepareHealthQuestions = () => {
      data.form.questions = healthScreeningQuestionList.value.map(
        (question) => {
          return {
            ...question,
            isVisitorAnsweredYes: false
          }
        }
      )
    }

    const prepareVisitorCheckInForm = () => {
      data.form = getInitialFormData()
      prepareHealthQuestions()
      initialFormData.value = cloneDeep(data.form)
    }

    const onBrotherPrinterPrint = (badge) => {
      modal.open(VisitorPrintPreviewModal, {
        size: "lg",
        title: visitorConstants.PRINTER_SETUP.PRINTER_PREVIEW_MODAL_TITLE,
        props: {
          badge
        }
      })
    }

    const onFirebaseMessageReceive = async (result) => {
      /**
       * When the visitor captures the photo from the app,
       * then the photo will be set in the form
       */
      if (result?.data?.imageUrl) {
        data.form.imageData = await passHelpers.imageUrlToBase64(
          result?.data?.imageUrl
        )
        data.form.profileImage = ""
        firebaseMessage.value =
          visitorConstants.CHECK_IN_FORM.FIREBASE_MESSAGE_PHOTO_CAPTURE_SUCCESS
      }

      /**
       * When the visitor checks in from the app and
       * self badge printing is enabled and the dymo printer is added,
       * then the badge will be printed
       */
      if (
        isDymoSelfBadgePrintingEnabled?.value &&
        isDymoPrinterAddedOnPrinterSetup?.value &&
        JSON.parse(result?.data?.print_required || "{}")?.print === "true"
      ) {
        const parsedDate = moment.utc(
          result?.data?.date,
          "MMM DD, YYYY - h:mm A"
        )
        try {
          const { success, message } = await generateMarkupAndStartPrint({
            firstName: result?.data?.firstName,
            lastName: result?.data?.lastName,
            date: passHelpers.convertToCurrentTimezone(
              parsedDate,
              true,
              "MMM DD, YYYY - h:mm A"
            ),
            reasonForVisit: result?.data?.reasonForVisit,
            imageURL:
              result?.data?.imageURL ||
              `${window.location.origin}/img/avatars/user-avatar.jpeg`,
            schoolName: activeSchool?.value?.name,
            destination: isDestinationSettingEnabled?.value
              ? result?.data?.destination
              : ""
          })
          if (!success) {
            throw new Error("Dymo Self Badge Printing failed : " + message)
          }
        } catch (error) {
          firebaseError.value = error
        }
      }

      if (
        result?.notification?.title?.toString()?.trim()?.toLowerCase() ===
        "new visitor ipad"
      ) {
        store.commit("visitorManage/SET_UPDATE_VISITOR_LOGS", true)
      }
    }

    /**
     * When user selects android or ios, send the token to backend
     * Using the token, backend will send the notification to the app and web
     */
    const onNotifyApp = async (device) => {
      const fcmService = getFcmService()
      if (!fcmService) return

      try {
        isLoading.value = true
        const token = await fcmService.requestPermissionAndGetToken()
        if (!token) return

        const params = {
          firebase_token: token,
          school_id: activeSchool?.value?.id || "",
          category: visitorConstants.VISITOR_CAMERA_REQUEST_CATEGORY[device]
        }
        await store.dispatch("visitorManage/sendApp", params)
        firebaseMessage.value =
          visitorConstants.CHECK_IN_FORM.FIREBASE_MESSAGE_PHOTO_CAPTURE_REQUEST_SENT
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    /**
     * This function is used to initialise the firebase
     * It returns the firebase cloud messaging token and listens to the incoming messages
     */
    const initialiseFirebase = async () => {
      const fcmService = getFcmService()
      if (!fcmService) return

      try {
        const token = await fcmService.requestPermissionAndGetToken()
        const userId = userDetail?.value?.id || ""
        await store.dispatch("visitorManage/sendFcmToken", {
          token,
          userId
        })
        fcmService.onMessageListener(onFirebaseMessageReceive)
      } catch (error) {
        firebaseError.value = error
      }
    }

    onMounted(async () => {
      try {
        isInitialLoading.value = true
        await passHelpers.execPromiseWithTimeout(
          initialiseFirebase(),
          10000,
          "Firebase took too long; However, you can still proceed with the check-in process."
        )
      } catch (error) {
        serverError.value = error
      } finally {
        isInitialLoading.value = false
      }
      prepareVisitorCheckInForm()
    })

    return {
      step,
      data,
      isLoading,
      isClearAllDisabled,
      isCheckInProcessInProgress,
      isInitialLoading,
      firebaseMessage,
      firebaseError,
      serverError,
      onBrotherPrinterPrint,
      onNotifyApp,
      onDetailFormSubmit,
      onHealthFormSubmit,
      onFormReset
    }
  }
}
</script>

<style scoped></style>
