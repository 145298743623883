<template>
  <div class="visitor-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: "VisitorWrapper"
}
</script>

<style scoped></style>
