<template>
  <div
    v-if="!isInitialLoading"
    class="d-flex flex-column gap-4"
    :class="{
      'visitor-loading': isLoading
    }"
  >
    <!-- LOG TABLE FILTERS -->
    <VisitorLogHistoryTableFilters
      :filter="state.filter"
      @on-filter-change="onFilterChange"
      @on-filter-reset="onFilterReset"
    />
    <!-- LOG TABLE FILTERS -->

    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- LOG TABLE -->
    <div class="bg-white rounded-30 p-4">
      <Perpage
        use-first-option-as-default
        use-dynamic-update
        showing-entries
        :options="pageOptions"
        :pagination="paginationWithExtra"
        @on-page-change="setPage"
      />
      <DataTable
        :fields="fields"
        :items="sortedList"
        :sorter="{ external: true }"
        column-filter
        @update:sorter-value="onSort"
      >
        <template #first_name="{ item }">
          <td>
            <VisitorNameColumn is-first-name :visitor="item" />
          </td>
        </template>

        <template #last_name="{ item }">
          <td>
            <VisitorNameColumn :visitor="item" />
          </td>
        </template>

        <template #phone_number="{ item }">
          <td>
            {{ formatPhoneNumber(item.profile.phone_number) }}
          </td>
        </template>

        <template #lookup_value="{ item }">
          <td>
            <VisitorReasonForVisitColumn :visitor="item" />
          </td>
        </template>

        <template #destination="{ item }">
          <td>
            <VisitorDestinationColumn :visitor="item" />
          </td>
        </template>

        <template #signed_in="{ item }">
          <td>
            <VisitorDateColumn :date="item.signed_in_date" />
          </td>
        </template>

        <template #signed_out="{ item }">
          <td v-if="!isSignedInOrDenied(item)">
            <VisitorDateColumn :date="item.signed_out_date" />
          </td>
          <td v-else>-</td>
        </template>

        <template #status="{ item }">
          <td>
            <VisitorStatusColumn :key="item.visit_id" :visitor="item" />
          </td>
        </template>

        <template v-if="isHealthScreeningEnabled" #health_check="{ item }">
          <td>
            <VisitorHealthCheckStatusColumn
              :visitor="item"
              @on-fail-health-check="onHealthCheck"
            />
          </td>
        </template>

        <template #action="{ item }">
          <td class="text-end">
            <VisitorRowActions
              v-if="getActions(item).length > 0"
              :actions="getActions(item)"
              item-class="visitor-row-action"
              @click-action="onActionClick($event, item)"
            />
            <div v-else style="height: 50px"></div>
          </td>
        </template>
      </DataTable>
      <Pagination
        v-if="pagination.pages > 1"
        :active-page="pagination.activePage"
        :pages="pagination.pages"
        class="cs-pagination mt-4"
        :dots="false"
        :double-arrows="true"
        size="lg"
        align="center"
        @update:active-page="setActivePage"
      ></Pagination>
    </div>
    <!-- LOG TABLE -->
  </div>
  <div v-else class="d-flex justify-content-center align-content-center gap-2">
    <CSpinner color="primary" />
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorLogHistoryTableFilters from "@/v3components/Datatables/Visitor/CheckIns/VisitorLogHistoryTableFilters.vue"
import VisitorRowActions from "@/v3components/Datatables/Visitor/CheckIns/VisitorRowActions.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import Perpage from "@/v3components/shared/DataTable/Perpage.vue"
import VisitorStatusColumn from "@/v3components/Datatables/Visitor/CheckIns/VisitorStatusColumn.vue"
import VisitorHealthCheckStatusColumn from "@/v3components/Datatables/Visitor/CheckIns/VisitorHealthCheckStatusColumn.vue"
import VisitorDateColumn from "@/v3components/Datatables/Visitor/CheckIns/VisitorDateColumn.vue"
import VisitorDestinationColumn from "@/v3components/Datatables/Visitor/CheckIns/VisitorDestinationColumn.vue"
import VisitorReasonForVisitColumn from "@/v3components/Datatables/Visitor/CheckIns/VisitorReasonForVisitColumn.vue"
import VisitorNameColumn from "@/v3components/Datatables/Visitor/CheckIns/VisitorNameColumn.vue"
import passHelpers from "@/helpers/index"
import visitorConstants from "@/constants/visitorConstants"
import visitorConfigs from "@/configs/visitorConfigs"
import { generateMarkupAndStartPrint } from "@/helpers/printerService"
import {
  computed,
  defineAsyncComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch
} from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import moment from "moment-timezone"
import { debounce } from "lodash"

const VisitorHealthCheckForm = defineAsyncComponent(
  () =>
    import("@/v3components/Forms/Visitor/CheckIns/VisitorHealthCheckForm.vue")
)

const VisitorPrintPreviewModal = defineAsyncComponent(
  () =>
    import("@/v3components/shared/VisitorPrint/VisitorPrintPreviewModal.vue")
)

export default {
  name: "VisitorLogHistoryTable",
  components: {
    DataTable,
    VisitorRowActions,
    Perpage,
    Pagination,
    VisitorLogHistoryTableFilters,
    VisitorStatusColumn,
    VisitorHealthCheckStatusColumn,
    VisitorDestinationColumn,
    VisitorDateColumn,
    VisitorReasonForVisitColumn,
    VisitorErrorHandler,
    VisitorNameColumn
  },
  setup() {
    const modal = inject("modal")
    const router = useRouter()
    const store = useStore()

    const serverError = ref(null)
    const isInitialLoading = ref(false)

    const getDatesValue = () => {
      const startDate = visitorConfigs.useCustomVisitorTimeFunction
        ? passHelpers.convertToCurrentTimezone(
            moment().subtract(6, "days"),
            false,
            "MM/DD/YYYY",
            true
          )
        : moment().subtract(6, "days").format("MM/DD/YYYY")
      const endDate = visitorConfigs.useCustomVisitorTimeFunction
        ? passHelpers.convertToCurrentTimezone(
            moment(),
            false,
            "MM/DD/YYYY",
            true
          )
        : moment().format("MM/DD/YYYY")
      return startDate + " - " + endDate
    }

    const getFilterInitialState = () => ({
      search: null,
      dates: getDatesValue(),
      first_name: null,
      last_name: null,
      date_of_birth: null,
      status: null,
      reason_for_visit: null,
      reason_for_visit_text: null,
      destination: null,
      destination_id: null,
      sorter: null
    })

    const state = reactive({
      filter: getFilterInitialState()
    })

    const pageOptions = [
      { label: "25 / Page", value: 25 },
      { label: "50 / Page", value: 50 },
      { label: "100 / Page", value: 100 }
    ]

    const allFields = [
      { key: "first_name", label: "First name", sorter: true, filter: false },
      { key: "last_name", label: "Last name", sorter: true, filter: false },
      {
        key: "phone_number",
        label: "Phone number",
        sorter: true,
        filter: false
      },
      {
        key: "lookup_value",
        label: "Reason for visit",
        sorter: true,
        filter: false
      },
      {
        key: "destination",
        label: "Destination",
        sorter: true,
        filter: false
      },
      {
        key: "signed_in",
        label: "Signed in",
        sorter: true,
        filter: false
      },
      {
        key: "signed_out",
        label: "Signed out",
        sorter: true,
        filter: false
      },
      { key: "status", label: "Status", sorter: true, filter: false },
      {
        key: "health_check",
        label: "Custom",
        _style: "width: 50px;",
        sorter: true,
        filter: false
      },
      {
        key: "action",
        label: "Action",
        _style: "width: 50px;",
        sorter: false,
        filter: false
      }
    ]

    const fields = computed(() => {
      let finalFields = [...allFields]

      if (!isHealthScreeningEnabled.value) {
        finalFields = finalFields.filter(
          (field) => field.key !== "health_check"
        )
      }

      if (!isDestinationEnabled.value) {
        finalFields = finalFields.filter((field) => field.key !== "destination")
      }

      return finalFields
    })

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const activeSchool = computed(
      () => store?.getters?.["schools/activeSchool"] || null
    )

    const schoolName = computed(() => activeSchool?.value?.name || "")

    const printer = computed(() => store.getters["visitorManage/printer"])

    const healthScreeningSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id === visitorConstants.SETTINGS.HEALTH_SCREENING
        ) || null
      )
    })

    const isHealthScreeningEnabled = computed(() => {
      return healthScreeningSetting?.value?.value === "true" || false
    })

    const destinationSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id === visitorConstants.SETTINGS.DESTINATION
        ) || null
      )
    })

    const isDestinationEnabled = computed(() => {
      return destinationSetting?.value?.value === "true" || false
    })

    const filterStartDate = computed(() => {
      return state.filter.dates.split(" - ")[0]
    })

    const filterEndDate = computed(() => {
      return state.filter.dates.split(" - ")[1]
    })

    const visitors = computed(() => store.getters["visitorManage/visitors"])

    const list = computed(() => getFormattedList(visitors?.value?.data || []))

    const hasNoPrinter = computed(
      () => store.getters["visitorManage/hasNoPrinter"]
    )

    const sortedList = computed(() => {
      if (state?.filter?.sorter) {
        const listCopy = [...list.value]
        const isAsc = state?.filter?.sorter?.asc || false
        switch (state.filter.sorter.column) {
          case "first_name":
            return listCopy.sort((a, b) =>
              compare(a.first_name, b.first_name, isAsc)
            )
          case "last_name":
            return listCopy.sort((a, b) =>
              compare(a.last_name, b.last_name, isAsc)
            )
          case "phone_number":
            return listCopy.sort((a, b) =>
              compare(a.phone_number, b.phone_number, isAsc)
            )
          case "lookup_value":
            return listCopy.sort((a, b) =>
              compare(a.reason_for_visit, b.reason_for_visit, isAsc)
            )
          case "destination":
            return listCopy.sort((a, b) =>
              compare(a.destination, b.destination, isAsc)
            )
          case "signed_in":
            return listCopy.sort((a, b) =>
              compare(
                moment(a.signed_in).unix(),
                moment(b.signed_in).unix(),
                isAsc
              )
            )
          case "signed_out":
            return listCopy.sort((a, b) =>
              compare(
                moment(a.signed_out).unix(),
                moment(b.signed_out).unix(),
                isAsc
              )
            )
          case "status":
            return listCopy.sort((a, b) => compare(a.status, b.status, isAsc))
          case "health_check":
            return listCopy.sort((a, b) =>
              compare(a.health_check, b.health_check, isAsc)
            )
          default:
            return listCopy
        }
      }
      return list.value
    })

    const pagination = computed(() => visitors?.value?.pagination || {})

    const updateVisitorLogs = computed(() => {
      return visitors?.value?.updateVisitorLogs || false
    })

    const paginationWithExtra = computed(() => {
      return {
        ...pagination.value,
        from:
          Number(pagination.value.activePage - 1) *
            Number(pagination.value.per_page.value) +
          1,
        to:
          Number(pagination.value.activePage - 1) *
            Number(pagination.value.per_page.value) +
          Number(pagination.value.per_page.value)
      }
    })

    const isLoading = computed(() => visitors?.value?.isLoading || false)

    const getActions = (item) => {
      const actions = []
      if (
        isHealthScreeningEnabled.value &&
        item?.health_check_status?.toLowerCase()?.trim() === "fail"
      ) {
        actions.push({
          label: "Custom Question",
          icon: "ri-todo-line",
          class: "important",
          action: "health_check"
        })
      }
      if (isSignedIn(item)) {
        actions.push({
          label: "Sign out",
          icon: "ri-logout-box-line",
          class: "important",
          action: "sign_out"
        })
        actions.push({
          label: "Print badge",
          icon: "ri-printer-line",
          class: "important",
          action: "print_badge"
        })
      }
      return actions
    }

    const compare = (a, b, isAsc) => {
      return (a < b ? -1 : a == b ? 0 : 1) * (isAsc ? 1 : -1)
    }

    const getFormattedList = (list) => {
      return (
        list?.map((item) => {
          return {
            ...item,
            first_name: item?.profile?.first_name,
            last_name: item?.profile?.last_name,
            phone_number: item?.profile?.phone_number,
            reason_for_visit: item?.lookup_value,
            destination: item?.destination,
            signed_in: item?.signed_in_date,
            signed_out: item?.signed_out_date,
            status: item?.visit_status,
            health_check: item?.health_check_status
          }
        }) || []
      )
    }

    const setPage = async (option) => {
      if (!option) return
      store.commit("visitorManage/SET_VISITORS_PAGINATION_PER_PAGE", option)
      await getVisitorList()
    }

    const setActivePage = async (page) => {
      if (!page) return
      store.commit("visitorManage/SET_VISITORS_PAGINATION_ACTIVE_PAGE", page)
      await getVisitorList()
    }

    const onActionClick = async (event, item) => {
      if (event.action == "health_check") {
        return onHealthCheck(item)
      }

      if (event.action == "sign_out") {
        return await onSignOut(item)
      }

      if (event.action == "print_badge") {
        if (hasNoPrinter.value) {
          router.push(visitorConfigs.pages.printerPage().path)
          return
        }

        return await onPrintBadge(item)
      }
    }

    const onHealthCheck = (visitor) => {
      modal.open(VisitorHealthCheckForm, {
        size: "sm",
        props: {
          visitor
        }
      })
    }

    const isStudentRelated = (visitor) => {
      const studentLookUpValues = ["student pick up", "student drop off"]
      const lookupValue = visitor?.lookup_value?.trim()?.toLowerCase()
      const reasonDescription = visitor?.reason_description
        ?.trim()
        ?.toLowerCase()
      return (
        studentLookUpValues.includes(lookupValue) ||
        studentLookUpValues.includes(reasonDescription)
      )
    }

    const formatDateTime = (date) => {
      const datePart = date?.split(" ")?.[0] || ""
      const timePart = date?.split(" ")?.[1] || ""
      return visitorConfigs?.useCustomVisitorTimeFunction
        ? passHelpers?.convertToCurrentTimezone(
            date,
            true,
            "MMM DD, YYYY - h:mm A"
          )
        : passHelpers.currTzDateTime(
            datePart,
            timePart,
            "MMM DD, YYYY - h:mm A"
          )
    }

    const onPrintBadge = async (item) => {
      const printerBrand =
        printer?.value?.printerBrand?.toString()?.trim()?.toLowerCase() || ""
      const payload = {
        firstName: item?.profile?.first_name || "Visitor",
        lastName: item?.profile?.last_name || "",
        date: formatDateTime(item?.signed_in_date),
        reasonForVisit: isStudentRelated(item)
          ? item?.lookup_value
          : item?.sub_reason_description || item?.visit_reason,
        imageURL:
          item?.profile?.profile_image_uri ||
          item?.profile?.profile_image_thumbnail_uri ||
          `${window.location.origin}/img/avatars/user-avatar.jpeg`,
        schoolName: schoolName?.value,
        destination: isDestinationEnabled?.value
          ? item?.destination_description || ""
          : ""
      }
      if (
        printerBrand?.includes(visitorConstants.PRINTER_CONFIG.BROTHER.BRAND)
      ) {
        modal.open(VisitorPrintPreviewModal, {
          size: "lg",
          title: visitorConstants.PRINTER_SETUP.PRINTER_PREVIEW_MODAL_TITLE,
          props: {
            badge: payload
          }
        })
      } else {
        try {
          const { success, message } =
            await generateMarkupAndStartPrint(payload)
          if (!success) {
            throw new Error(message)
          }
        } catch (error) {
          serverError.value = error
        }
      }
    }

    const isSignedIn = (item) => {
      return item?.visit_status
        ?.toString()
        ?.trim()
        ?.toLowerCase()
        ?.includes("signed in")
    }

    const isSignedInOrDenied = (item) => {
      return (
        item?.visit_status
          ?.toString()
          ?.trim()
          ?.toLowerCase()
          ?.includes("signed in") ||
        item?.visit_status
          ?.toString()
          ?.trim()
          ?.toLowerCase()
          ?.includes("denied")
      )
    }

    const onSignOut = async (item) => {
      try {
        serverError.value = null
        const payload = {
          ids: [item.visit_id],
          all: false
        }
        await store.dispatch("visitorManage/checkOutVisitors", payload)
        await getVisitorList()
      } catch (error) {
        serverError.value = error
      }
    }

    const onFilterChange = async (filter, shouldReset = false) => {
      state.filter = filter
      if (shouldReset) {
        store.commit("visitorManage/SET_VISITORS_PAGINATION_ACTIVE_PAGE", 1)
        store.commit("visitorManage/SET_VISITORS_PAGINATION_PER_PAGE", {
          label: "25 / Page",
          value: 25
        })
      }
      await getVisitorList()
    }

    const onFilterReset = async () => {
      state.filter = getFilterInitialState()
      store.commit("visitorManage/SET_VISITORS_PAGINATION_ACTIVE_PAGE", 1)
      store.commit("visitorManage/SET_VISITORS_PAGINATION_PER_PAGE", {
        label: "25 / Page",
        value: 25
      })
      await getVisitorList()
    }

    const getVisitorList = debounce(async () => {
      const payload = {
        visitorName: state.filter.search,
        startDate: passHelpers.currTzDate(filterStartDate.value, "YYYY-MM-DD"),
        endDate: passHelpers.currTzDate(filterEndDate.value, "YYYY-MM-DD")
      }

      if (state?.filter?.status?.value) {
        payload.status = state.filter.status.value
      }

      if (state?.filter?.reason_for_visit?.value) {
        payload.reasonForVisit = state.filter.reason_for_visit.value.id
        payload.reasonForVisitText =
          state.filter.reason_for_visit.value.lookup_value
      }

      if (state?.filter?.destination?.value) {
        if (
          state?.filter?.destination?.value?.destination
            ?.toString()
            ?.toUpperCase() === "OTHERS"
        ) {
          payload.destinationId = state.filter.destination.value.id
        } else {
          if (state?.filter?.destination?.value?.old) {
            payload.destination = state.filter.destination.value.displayText
          } else {
            payload.destinationId = state.filter.destination.value.id
            payload.destination = state.filter.destination.value.displayText
          }
        }
      }

      try {
        serverError.value = null
        await store.dispatch("visitorManage/getVisitorList", payload)
      } catch (error) {
        serverError.value = error
      }
    }, 500)

    const formatPhoneNumber = (phoneNumber) => {
      return phoneNumber ? passHelpers.phoneNumberFormatter(phoneNumber) : ""
    }

    const onSort = async (sorter) => {
      state.filter.sorter = sorter
    }

    onMounted(async () => {
      try {
        isInitialLoading.value = true
        await getVisitorList()
      } catch (error) {
        serverError.value = error
      } finally {
        isInitialLoading.value = false
      }
    })

    onUnmounted(async () => {
      await onFilterReset()
    })

    watch(
      () => updateVisitorLogs.value,
      async (newValue) => {
        if (newValue) {
          await onFilterReset()
          store.commit("visitorManage/SET_UPDATE_VISITOR_LOGS", false)
        }
      }
    )

    return {
      isInitialLoading,
      isLoading,
      isSignedInOrDenied,
      isHealthScreeningEnabled,
      serverError,
      state,
      list,
      pageOptions,
      pagination,
      paginationWithExtra,
      fields,
      getActions,
      onActionClick,
      setPage,
      setActivePage,
      formatPhoneNumber,
      onHealthCheck,
      onFilterChange,
      onFilterReset,
      sortedList,
      onSort
    }
  }
}
</script>

<style scoped>
.w-250px {
  width: 250px;
}
</style>
