<template>
  <main>
    <div
      class="c-app flex-row align-items-center"
      :class="{ 'c-dark-theme': $store.state.layout.darkMode }"
    >
      <CContainer>
        <h1 class="sr-only">Forgotten Password</h1>
        <CRow class="justify-content-center">
          <CCol xs="12" md="6" lg="5">
            <CCardGroup>
              <CCard class="p-4">
                <CCardBody>
                  <CForm>
                    <div class="d-flex justify-content-center mb-4">
                      <img
                        src="@/assets/images/securlypass-color-logo.png"
                        alt="Securly Pass Logo"
                        class="small-logo"
                      />
                    </div>

                    <CInputGroup
                      data-test-id="forgotten-password-email"
                      class="mb-0"
                      :class="{
                        'is-invalid':
                          v$.user.$error && v$.user.email.required.$invalid
                      }"
                    >
                      <CInputGroupText
                        class="input-group-prepend"
                        aria-hidden="true"
                      >
                        <CIcon icon="cil-user" aria-label="User Icon" />
                      </CInputGroupText>
                      <CFormInput
                        class="mb-0"
                        :class="{
                          'is-invalid':
                            v$.user.$error && v$.user.email.required.$invalid
                        }"
                        placeholder="Email"
                        autocomplete="email"
                        :is-valid="checkIfValid('email')"
                        v-model="v$.user.email.$model"
                        aria-label="Email Address"
                        aria-required="true"
                      >
                      </CFormInput>
                    </CInputGroup>
                    <div
                      data-test-id="forgotten-password-error"
                      v-if="v$.user.$error && v$.user.email.required.$invalid"
                      class="invalid-feedback"
                      role="alert"
                    >
                      This field is required
                    </div>

                    <CAlert
                      v-if="reqResponse.message"
                      data-test-id="forgotten-password-error"
                      class="mt-3 mb-0"
                      :color="reqResponse.type"
                      :show="!!reqResponse.message"
                      role="alert"
                    >
                      {{ reqResponse.message }}
                      <div v-if="reqResponse.errors">
                        <div v-if="(err, index) in reqResponse.errors">
                          {{ err[0] }}
                        </div>
                      </div>
                    </CAlert>
                    <CRow>
                      <CCol col="12" class="text-center">
                        <div class="mt-3" @click="resetPass()">
                          <CLoadingButton
                            data-test-id="forgotten-password-submit"
                            class="me-2 btn bg-gradient-blue text-white"
                            :loading="isLoading"
                            aria-label="Reset Password"
                          >
                            Reset Password
                          </CLoadingButton>
                        </div>
                      </CCol>
                    </CRow>
                    <p class="text-muted mt-4 text-center">
                      Enter your email to reset your password.
                    </p>
                  </CForm>
                  <div class="d-flex justify-content-center">
                    <router-link
                      to="/login"
                      aria-label="Back to Login"
                      class="btn btn-link"
                    >
                      Back to Login
                    </router-link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </main>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import useVuelidate from "@vuelidate/core"

export default {
  name: "ForgottenPassword",
  setup() {
    return { v$: useVuelidate() }
  },
  data: () => ({
    isLoading: false,
    user: {
      email: ""
    },
    reqResponse: {
      type: "",
      message: "",
      errors: {}
    }
  }),
  validations: {
    user: {
      email: {
        required
      }
    }
  },
  computed: {
    isValid() {
      return !this.v$.user.$invalid
    }
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.v$.user[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "")
    },
    resetPass() {
      this.resetResponseMessages()
      if (this.isValid) {
        this.isLoading = true
        this.$store
          .dispatch("authentication/resetPassword", this.user)
          .then(() => {
            this.isLoading = false
            this.setSuccessResponse("Password reset email successfully sent.")
          })
          .catch((err) => {
            this.isLoading = false
            const response = err && err.response ? err.response.data : null
            if (response) {
              this.setErrorResponse(
                response.message || err.message,
                response.errors
              )
            } else {
              this.setErrorResponse(err.message, null)
            }
          })
      } else {
        this.v$.$touch()
      }
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }
  }
}
</script>
