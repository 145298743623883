<template>
  <div class="d-flex flex-column gap-4">
    <div>
      <h3 class="text-center -mt-2">{{ title }}</h3>
      <p class="text-center">
        {{ description }}
      </p>
      <p v-if="note" class="visitor-allow-deny-note text-center">
        <span class="font-bold">Note:</span>
        {{ note }}
      </p>
    </div>
    <div class="d-flex justify-content-center gap-4">
      <VisitorMatchUserDetail
        :user="currentVisitor"
        :use-different-key-for-visitor-dob="useDifferentKeyForVisitorDob"
      />
      <div class="visitor-user-profile-root-red-border-container">
        <div class="visitor-match-user-nav-button">
          <IconButton
            :class="{
              'visible-show': currentIndex > 0,
              'visible-hide': currentIndex === 0
            }"
            icon="ri-arrow-left-s-line"
            @click="onPrevious"
          />
        </div>
        <VisitorMatchUserDetail :key="currentIndex" :user="currentOffender" />
        <div class="visitor-match-user-nav-button">
          <IconButton
            :class="{
              'visible-show': currentIndex < matchList.length - 1,
              'visible-hide': currentIndex === matchList.length - 1
            }"
            icon="ri-arrow-right-s-line"
            @click="onNext"
          />
        </div>
      </div>
    </div>

    <div
      v-if="showAllowAndDenyActions"
      class="d-flex justify-content-center align-content-center gap-4"
    >
      <BaseButton rounded @click="onAllow">Allow entry</BaseButton>
      <BaseButton rounded solid @click="onDeny">Deny Entry</BaseButton>
    </div>

    <div
      v-if="showCancelAndProceedActions"
      class="d-flex flex-column align-items-center gap-4"
    >
      <p class="visitor-info-note text-center">
        {{ visitorConstants.CHECK_IN_FORM.VISITOR_NOT_RECENT_CHECK_IN_MESSAGE }}
      </p>
      <div class="d-flex justify-content-center align-content-center gap-4">
        <BaseButton rounded @click="onCancel">Cancel</BaseButton>
        <BaseButton rounded solid @click="onProceed">
          Show Next If Exists
        </BaseButton>
      </div>
    </div>

    <div
      v-if="showMatchAndNotAMatchActions"
      class="d-flex justify-content-center align-content-center gap-4"
    >
      <BaseButton rounded @click="onNoMatch">Not a match</BaseButton>
      <BaseButton rounded solid @click="onMatch">Match</BaseButton>
    </div>
  </div>
</template>

<script>
import VisitorMatchUserDetail from "@/v3components/Forms/Visitor/CheckIns/VisitorMatchUserDetail.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import { computed, ref } from "vue"

export default {
  name: "VisitorMatchPopUp",
  components: {
    VisitorMatchUserDetail,
    BaseButton,
    IconButton
  },
  props: {
    type: {
      type: String,
      default: "",
      validator: (value) => ["watchlist", "offender"].includes(value)
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    note: {
      type: String,
      default: ""
    },
    visitor: {
      type: Object,
      default: () => ({})
    },
    watchList: {
      type: Array,
      default: () => []
    },
    offenderList: {
      type: Array,
      default: () => []
    },
    match: {
      type: Function,
      default: () => ({})
    },
    noMatch: {
      type: Function,
      default: () => ({})
    },
    useDifferentKeyForVisitorDob: {
      type: Boolean,
      default: false
    },
    isInVisitorDeniedFlow: {
      type: Boolean,
      default: false
    },
    isVisitorRecentlySignedIn: {
      type: Boolean,
      default: false
    },
    allow: {
      type: Function,
      default: () => ({})
    },
    deny: {
      type: Function,
      default: () => ({})
    },
    cancel: {
      type: Function,
      default: () => ({})
    },
    proceed: {
      type: Function,
      default: () => ({})
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const currentIndex = ref(0)

    /**
     * Computes the current visitor details with a specific type.
     * @returns {Object} The current visitor details.
     */
    const currentVisitor = computed(() => {
      return {
        ...props.visitor,
        type: visitorConstants.CHECK_IN_FORM.MATCH_TYPE_VISITOR
      }
    })

    /**
     * Computes the list of matched users from watchList and offenderList based on the visitor.
     * @returns {Array} The list of matched users.
     */
    const matchList = computed(() => {
      return [
        ...(props?.watchList?.map((user) => ({
          ...user,
          type: visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
        })) || []),
        ...(props?.offenderList?.map((user) => ({
          ...user,
          type: visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
        })) || [])
      ]
    })

    /**
     * Computes the current offender details based on the current index.
     * @returns {Object} The current offender details.
     */
    const currentOffender = computed(() => {
      return { ...matchList.value[currentIndex.value] }
    })

    /**
     * Determines whether to show allow and deny actions.
     * @returns {Boolean} True if in visitor denied flow and visitor recently signed in, otherwise false.
     */
    const showAllowAndDenyActions = computed(
      () => props.isInVisitorDeniedFlow && props.isVisitorRecentlySignedIn
    )

    /**
     * Determines whether to show cancel and proceed actions.
     * @returns {Boolean} True if in visitor denied flow and visitor not recently signed in, otherwise false.
     */
    const showCancelAndProceedActions = computed(
      () => props.isInVisitorDeniedFlow && !props.isVisitorRecentlySignedIn
    )

    /**
     * Determines whether to show match and not a match actions.
     * @returns {Boolean} True if not in visitor denied flow, otherwise false.
     */
    const showMatchAndNotAMatchActions = computed(
      () => !props.isInVisitorDeniedFlow
    )

    const onNext = () => {
      if (currentIndex.value < matchList.value.length - 1) {
        currentIndex.value += 1
      }
    }

    const onPrevious = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1
      }
    }

    const onMatch = () => {
      props.match(currentOffender.value)
    }

    const onNoMatch = () => {
      props.noMatch(currentOffender.value)
      setTimeout(() => {
        emit("cancel")
      })
    }

    const onAllow = () => {
      props.allow(currentOffender.value)
    }

    const onCancel = () => {
      props.cancel()
      setTimeout(() => {
        emit("cancel")
      })
    }

    const onProceed = () => {
      props.proceed()
      setTimeout(() => {
        emit("cancel")
      })
    }

    const onDeny = () => {
      props.deny()
      setTimeout(() => {
        emit("cancel")
      })
    }

    return {
      visitorConstants,
      currentVisitor,
      matchList,
      currentIndex,
      currentOffender,
      showAllowAndDenyActions,
      showCancelAndProceedActions,
      showMatchAndNotAMatchActions,
      onMatch,
      onNoMatch,
      onNext,
      onPrevious,
      onAllow,
      onCancel,
      onProceed,
      onDeny
    }
  }
}
</script>

<style scoped>
.visible-show {
  visibility: visible;
}

.visible-hide {
  visibility: hidden;
}

.-mt-2 {
  margin-top: -2rem;
}
</style>
