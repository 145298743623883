<template>
  <div class="d-flex flex-column gap-4">
    <h3 class="text-center text-red -mt-2">{{ popUp.title }}</h3>
    <div class="d-flex flex-column gap-1">
      <p>
        {{ popUp.message }}
      </p>
      <div v-if="showVisitorAndStudents" class="d-flex gap-4 mb-4">
        <div class="visitor-non-guardian-container">
          <div class="text-center font-bold text-uppercase">Visitor:</div>
          <div class="visitor-non-guardian-attribute">
            <div class="label">First Name:</div>
            <div class="value">
              {{ visitor?.visitor_first_name || visitor?.first_name }}
            </div>
          </div>
          <div class="visitor-non-guardian-attribute">
            <div class="label">Last Name:</div>
            <div class="value">
              {{ visitor?.visitor_last_name || visitor?.last_name }}
            </div>
          </div>
        </div>
        <div class="visitor-non-guardian-container student-list">
          <div class="text-center font-bold text-uppercase">
            Guardian Details:
          </div>
          <div
            v-for="(studentRecord, index) in students"
            :key="studentRecord.student.studentId"
            class="d-flex flex-column gap-2 mb-4"
          >
            <div class="visitor-non-guardian-attribute font-bold">
              <div class="label">Student {{ index + 1 }}:</div>
              <div class="value">
                {{ studentRecord?.student?.firstName }}
                {{ studentRecord?.student?.lastName }}
              </div>
            </div>
            <div
              v-for="(guardian, gIndex) in studentRecord.guardians"
              :key="guardian.gIndex + '-' + guardian.phoneNumber"
              class="d-flex flex-column"
            >
              <div>
                <div class="text-left font-bold">
                  Guardian {{ gIndex + 1 }}:
                </div>
                <div class="d-flex justify-content-center align-content-center">
                  <div class="d-flex flex-column gap-1">
                    <div class="visitor-non-guardian-attribute gap-2">
                      <div class="label">First name:</div>
                      <div class="value">
                        {{ guardian?.firstName || "-" }}
                      </div>
                    </div>
                    <div class="visitor-non-guardian-attribute gap-2">
                      <div class="label">Last name:</div>
                      <div class="value">
                        {{ guardian?.lastName || "-" }}
                      </div>
                    </div>
                    <div class="visitor-non-guardian-attribute gap-2">
                      <div class="label">Phone Number:</div>
                      <div class="value">
                        {{ formatPhoneNumber(guardian?.phoneNumber) || "-" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-if="showAllowRiskNote" class="visitor-allow-deny-note">
        <span class="font-bold">Note:</span>
        {{ popUp.note }}
      </p>
    </div>
    <div
      v-if="showAllowAndDenyActions"
      class="d-flex justify-content-center align-content-center gap-4"
    >
      <BaseButton rounded @click="onAllow">Allow entry</BaseButton>
      <BaseButton rounded solid @click="onDeny">Deny entry</BaseButton>
    </div>
    <div
      v-if="showCancelAndProceedActions"
      class="d-flex flex-column align-items-center gap-4"
    >
      <p class="visitor-info-note text-center">
        {{ visitorConstants.CHECK_IN_FORM.VISITOR_NOT_RECENT_CHECK_IN_MESSAGE }}
      </p>
      <div class="d-flex justify-content-center align-content-center gap-4">
        <BaseButton rounded @click="onCancel">Cancel</BaseButton>
        <BaseButton rounded solid @click="onProceed">
          Show Next If Exists
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import passHelpers from "@/helpers/index"
import { computed } from "vue"

const staticData = {
  "health-check": {
    title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_HEALTH_CHECK_FAILED,
    message:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_HEALTH_CHECK_FAILED_MESSAGE,
    note: visitorConstants.CHECK_IN_FORM.MODAL_ALLOW_HEALTH_CHECK_FAILED
  },
  "non-guardian-visitor": {
    title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_NON_GUARDIAN_VISITOR,
    message:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_NON_GUARDIAN_VISITOR_MESSAGE,
    note: visitorConstants.CHECK_IN_FORM.MODAL_ALLOW_RISK_NOTE
  },
  "non-pre-approved-visitor": {
    title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_NON_PRE_APPROVED_VISITOR,
    message:
      visitorConstants.CHECK_IN_FORM
        .MODAL_TITLE_NON_PRE_APPROVED_VISITOR_MESSAGE,
    note: visitorConstants.CHECK_IN_FORM.MODAL_ALLOW_RISK_NOTE
  },
  watchlist: {
    title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_WATCHLIST,
    message: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_WATCHLIST_MESSAGE,
    note: visitorConstants.CHECK_IN_FORM.MODAL_ALLOW_RISK_NOTE
  },
  offender: {
    title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_OFFENDER,
    message: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_OFFENDER_MESSAGE,
    note: visitorConstants.CHECK_IN_FORM.MODAL_ALLOW_RISK_NOTE
  }
}

export default {
  name: "VisitorAllowDenyPopUp",
  components: {
    BaseButton
  },
  props: {
    type: {
      type: String,
      default: "health-check",
      validator: (value) => Object.keys(staticData).includes(value)
    },
    allow: {
      type: Function,
      default: () => ({})
    },
    deny: {
      type: Function,
      default: () => ({})
    },
    cancel: {
      type: Function,
      default: () => ({})
    },
    proceed: {
      type: Function,
      default: () => ({})
    },
    visitor: {
      type: Object,
      default: () => ({})
    },
    students: {
      type: Array,
      default: () => []
    },
    isInVisitorDeniedFlow: {
      type: Boolean,
      default: false
    },
    isVisitorRecentlySignedIn: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    /**
     * Computes the popup data based on the type.
     * @returns {Object} The popup data.
     */
    const popUp = computed(() => staticData[props.type])

    /**
     * Determines whether to show visitor and students details.
     * @returns {Boolean} True if type is non-guardian-visitor and visitor and students data are available, otherwise false.
     */
    const showVisitorAndStudents = computed(() => {
      return (
        props?.type === "non-guardian-visitor" &&
        Object.keys(props?.visitor || {}).length > 0 &&
        props?.students?.length > 0
      )
    })

    /**
     * Determines whether to show allow and deny actions.
     * @returns {Boolean} True if not in visitor denied flow or visitor recently signed in, otherwise false.
     */
    const showAllowAndDenyActions = computed(
      () => !props.isInVisitorDeniedFlow || props.isVisitorRecentlySignedIn
    )

    /**
     * Determines whether to show cancel and proceed actions.
     * @returns {Boolean} True if in visitor denied flow and visitor not recently signed in, otherwise false.
     */
    const showCancelAndProceedActions = computed(() => {
      if (!props.isInVisitorDeniedFlow) {
        return false
      }
      return !props.isVisitorRecentlySignedIn
    })

    /**
     * Determines whether to show allow risk note.
     * @returns {Boolean} True if allow and deny actions are shown, otherwise false.
     */
    const showAllowRiskNote = computed(() => {
      return showAllowAndDenyActions.value
    })

    const formatPhoneNumber = (phoneNumber) => {
      return phoneNumber ? passHelpers.phoneNumberFormatter(phoneNumber) : ""
    }

    const onAllow = () => {
      props.allow()
    }

    const onDeny = () => {
      props.deny()
      emit("cancel")
    }

    const onCancel = () => {
      props.cancel()
      emit("cancel")
    }

    const onProceed = () => {
      props.proceed()
      // TODO - Remove this timeout and emit directly from the proceed function
      setTimeout(() => {
        emit("cancel")
      })
    }

    return {
      showVisitorAndStudents,
      showAllowRiskNote,
      showAllowAndDenyActions,
      showCancelAndProceedActions,
      popUp,
      onAllow,
      onDeny,
      onCancel,
      onProceed,
      formatPhoneNumber,
      visitorConstants
    }
  }
}
</script>

<style scoped>
.-mt-2 {
  margin-top: -2rem;
}

.text-red {
  color: red;
}

.font-bold {
  font-weight: bold;
}
</style>
