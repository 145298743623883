<template>
  <div
    class="d-flex justify-content-center text-center align-content-center flex-wrap gap-2"
  >
    <div class="visitor-row-wrapper">
      {{ reasonForVisit }}
    </div>
    <i
      v-if="reasonForVisitTooltip"
      :title="reasonForVisitTooltip"
      class="ri-information-line visitor-reason-for-visit-icon"
    />
  </div>
</template>

<script>
import visitorUtils from "@/helpers/visitorUtils"
import { computed } from "vue"

export default {
  name: "VisitorReasonForVisitColumn",
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const isStudentRelated = computed(() =>
      visitorUtils.forReports.isStudentRelatedCheckIn(props?.visitor)
    )

    const reasonForVisit = computed(() => {
      const lookupValue = props?.visitor?.lookup_value?.trim()?.toLowerCase()
      const reasonDescription = props?.visitor?.reason_description
      const subReasonDescription = props?.visitor?.sub_reason_description

      let values = []
      if (isStudentRelated.value) {
        const studentLookupMap = {
          "student pick up": "SPU",
          "student drop off": "SDO"
        }
        values = [
          studentLookupMap[lookupValue] || lookupValue,
          subReasonDescription
        ]
      } else {
        values = [subReasonDescription || reasonDescription]
      }
      values = Array.from(new Set(values))
      return values.filter(Boolean).join(" - ")
    })

    const reasonForVisitTooltip = computed(() => {
      const students = props?.visitor?.students?.split("|")?.join(", ") || ""
      return students ? `Student(s): ${students}` : ""
    })

    return {
      reasonForVisit,
      reasonForVisitTooltip
    }
  }
}
</script>

<style scoped></style>
