import visitorConstants from "@/constants/visitorConstants"
import visitorUtils from "@/helpers/visitorUtils"
import store from "@/store/index"
import VisitorAllowDenyPopUp from "@/v3components/Forms/Visitor/CheckIns/VisitorAllowDenyPopUp.vue"
import VisitorExceptionReasonPopUp from "@/v3components/Forms/Visitor/CheckIns/VisitorExceptionReasonPopUp.vue"
import VisitorHealthCheckForm from "@/v3components/Forms/Visitor/CheckIns/VisitorHealthCheckForm.vue"
import VisitorMatchPopUp from "@/v3components/Forms/Visitor/CheckIns/VisitorMatchPopUp.vue"
import { computed, reactive } from "vue"
import { useToast } from "vue-toastification"

const toast = useToast()

const updateVisitor = async (visitor, data, visitStatus) => {
  const isRecentlySignedIn =
    visitorUtils.forTime.isVisitorRecentlySignedIn(visitor)
  if (!isRecentlySignedIn) {
    return { success: false }
  }

  try {
    const payload = {
      visit_id: visitor?.visit_id || null,
      visitor_id: visitor?.visitor_id || null,
      visit_status: visitStatus,
      exception_message:
        visitorUtils.forDeniedFlow.getExceptionMessageFromResults(data)
    }
    await store.dispatch("visitorManage/updateVisitor", payload)
    return { success: true }
  } catch (error) {
    const isDeny =
      visitStatus === visitorConstants.CHECK_IN_FORM.VISIT_STATUS_DENIED
    toast.error(
      isDeny
        ? visitorConstants.CHECK_IN_FORM.ERROR_DENYING_VISITOR
        : visitorConstants.CHECK_IN_FORM.ERROR_SIGNING_IN_VISITOR
    )
    return { success: false }
  }
}

const isPreApprovedVisitorSettingsEnabled = () => {
  const school = computed(() => store.getters["visitorSettings/school"])
  return computed(() =>
    visitorUtils.forSettings.isSettingsEnabled(
      school.value,
      visitorConstants.SETTINGS.PRE_APPROVED_VISITOR
    )
  ).value
}

const isHealthScreeningSettingEnabled = () => {
  const school = computed(() => store.getters["visitorSettings/school"])
  return computed(() =>
    visitorUtils.forSettings.isSettingsEnabled(
      school.value,
      visitorConstants.SETTINGS.HEALTH_SCREENING
    )
  ).value
}

const openMatchPopUp = async (
  { title, description, note, watchList, offenderList, isOffenderType },
  visitor,
  data,
  modal
) => {
  return new Promise((resolve) => {
    const isRecentlySignedIn =
      visitorUtils.forTime.isVisitorRecentlySignedIn(visitor)
    modal.open(VisitorMatchPopUp, {
      size: "lg",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        useDifferentKeyForVisitorDob: true,
        isInVisitorDeniedFlow: true,
        isVisitorRecentlySignedIn: isRecentlySignedIn,
        type: isOffenderType
          ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
          : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST,
        title,
        description,
        note,
        visitor,
        watchList,
        offenderList,
        allow: (matchedUser) => {
          const matchType =
            (matchedUser?.type?.toLowerCase()?.trim() || "") ===
            visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
              ? visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
              : visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST

          showVisitorWOVExceptionReasonForm(
            { match_type: matchType },
            visitor,
            data,
            modal
          ).then((allowedResolve) => resolve(allowedResolve))
        },
        deny: () => {
          data.results[
            visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST
          ].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_OFFENDER_WATCHLIST_MATCH
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        },
        cancel: () => {
          data.results[
            visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST
          ].message =
            visitorConstants.CHECK_IN_FORM.CANCELLED_BY_USER_DURING_OFFENDER_WATCHLIST
          resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
        },
        proceed: () => {
          resolve(visitorConstants.DENIED_FLOW_STATUS.CONTINUE)
        }
      }
    })
  })
}

const showVisitorWOVExceptionReasonForm = (payload, visitor, data, modal) => {
  return new Promise((resolve) => {
    modal.open(VisitorExceptionReasonPopUp, {
      size: "sm",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        isInVisitorDeniedFlow: true,
        type: payload.match_type,
        signIn: async (reason) => {
          data.results[
            visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST
          ].message = reason
          resolve(visitorConstants.DENIED_FLOW_STATUS.SIGNIN)
        },
        deny: () => {
          data.results[
            visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST
          ].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_OFFENDER_WATCHLIST
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        }
      }
    })
  })
}

const showVisitorNGVAllowDenyForm = (students, visitor, data, modal) => {
  return new Promise((resolve) => {
    const isRecentlySignedIn =
      visitorUtils.forTime.isVisitorRecentlySignedIn(visitor)
    modal.open(VisitorAllowDenyPopUp, {
      size: "sm",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        type: "non-guardian-visitor",
        isInVisitorDeniedFlow: true,
        isVisitorRecentlySignedIn: isRecentlySignedIn,
        visitor,
        students,
        allow: () => {
          return showNonGuardianVisitorExceptionReasonForm(
            visitor,
            data,
            modal
          ).then((allowedResolve) => resolve(allowedResolve))
        },
        deny: async () => {
          data.results[visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_NON_GUARDIAN
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        },
        proceed: () => {
          resolve(visitorConstants.DENIED_FLOW_STATUS.CONTINUE)
        },
        cancel: () => {
          resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
        }
      }
    })
  })
}

const showNonGuardianVisitorExceptionReasonForm = (visitor, data, modal) => {
  return new Promise((resolve) => {
    modal.open(VisitorExceptionReasonPopUp, {
      size: "sm",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        isInVisitorDeniedFlow: true,
        type: "non-guardian-visitor",
        signIn: async (reason) => {
          data.results[visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN].message =
            reason
          resolve(visitorConstants.DENIED_FLOW_STATUS.SIGNIN)
        },
        deny: () => {
          data.results[visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_NON_GUARDIAN_EXCEPTION
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        }
      }
    })
  })
}

const showHealthCheckFailedAllowDenyForm = (visitor, data, modal) => {
  return new Promise((resolve) => {
    const isRecentlySignedIn =
      visitorUtils.forTime.isVisitorRecentlySignedIn(visitor)
    modal.open(VisitorHealthCheckForm, {
      size: "sm",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        visitor,
        isInVisitorDeniedFlow: true,
        isVisitorRecentlySignedIn: isRecentlySignedIn,
        allow: () => {
          return showHealthCheckFailedExceptionReasonForm(
            visitor,
            data,
            modal
          ).then((allowedResolve) => resolve(allowedResolve))
        },
        deny: async () => {
          data.results[
            visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION
          ].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_HEALTH_CHECK
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        },
        proceed: () => {
          resolve(visitorConstants.DENIED_FLOW_STATUS.CONTINUE)
        },
        cancel: () => {
          resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
        }
      }
    })
  })
}

const showHealthCheckFailedExceptionReasonForm = (_visitor, data, modal) => {
  return new Promise((resolve) => {
    modal.open(VisitorExceptionReasonPopUp, {
      size: "sm",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        isInVisitorDeniedFlow: true,
        type: "health-check",
        signIn: async (reason) => {
          data.results[
            visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION
          ].message = reason
          resolve(visitorConstants.DENIED_FLOW_STATUS.SIGNIN)
        },
        deny: () => {
          data.results[
            visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION
          ].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_HEALTH_CHECK_EXCEPTION
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        }
      }
    })
  })
}

const showNonPreApprovedVisitorAllowDenyForm = (visitor, data, modal) => {
  return new Promise((resolve) => {
    const isRecentlySignedIn =
      visitorUtils.forTime.isVisitorRecentlySignedIn(visitor)
    modal.open(VisitorAllowDenyPopUp, {
      size: "sm",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        type: "non-pre-approved-visitor",
        isInVisitorDeniedFlow: true,
        isVisitorRecentlySignedIn: isRecentlySignedIn,
        allow: () => {
          return showNonPreApprovedVisitorExceptionReasonForm(
            visitor,
            data,
            modal
          ).then((allowedResolve) => resolve(allowedResolve))
        },
        deny: async () => {
          data.results[visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_NON_PRE_APPROVED
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        },
        proceed: () => {
          resolve(visitorConstants.DENIED_FLOW_STATUS.CONTINUE)
        },
        cancel: () => {
          resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
        }
      }
    })
  })
}

const showNonPreApprovedVisitorExceptionReasonForm = (visitor, data, modal) => {
  return new Promise((resolve) => {
    modal.open(VisitorExceptionReasonPopUp, {
      size: "sm",
      addOnModalClose: true,
      onModalClose: () => {
        resolve(visitorConstants.DENIED_FLOW_STATUS.CANCEL)
      },
      props: {
        isInVisitorDeniedFlow: true,
        type: "non-pre-approved-visitor",
        signIn: async (reason) => {
          data.results[visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED].message =
            reason
          resolve(visitorConstants.DENIED_FLOW_STATUS.SIGNIN)
        },
        deny: () => {
          data.results[visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED].message =
            visitorConstants.CHECK_IN_FORM.DENIED_BY_USER_DURING_NON_PRE_APPROVED_EXCEPTION
          resolve(visitorConstants.DENIED_FLOW_STATUS.DENY)
        }
      }
    })
  })
}

const handleOffenderWatchlist = async (visitor, data, modal) => {
  const watchlistOffenderPayload =
    visitorUtils.forPayload.watchlistOffenderCheckPayload(visitor)
  const { result: offenderList, success: offenderSuccess } =
    await visitorUtils.forChecks.checkOffender(watchlistOffenderPayload)
  const { result: watchList, success: watchListSuccess } =
    await visitorUtils.forChecks.checkWatchlist(watchlistOffenderPayload)

  if (!offenderSuccess || !watchListSuccess) {
    data.results[visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST].message =
      visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_OFFENDER_WATCHLIST
    toast.error(
      visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_OFFENDER_WATCHLIST
    )
    return visitorConstants.DENIED_FLOW_STATUS.CANCEL
  }

  if (offenderList.length === 0 && watchList.length === 0) {
    data.results[visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST].message =
      visitorConstants.CHECK_IN_FORM.NO_MATCHES_FOUND_OFFENDER_WATCHLIST
    toast.info(
      visitorConstants.CHECK_IN_FORM.NO_MATCHES_FOUND_OFFENDER_WATCHLIST
    )
    return visitorConstants.DENIED_FLOW_STATUS.CONTINUE
  }

  const { title, description } =
    visitorUtils.forDeniedFlow.getOffenderWatchlistModalContent({
      isBoth: offenderList?.length > 0 && watchList?.length > 0,
      isOffenderType: offenderList?.length > 0,
      isRecentlySignedIn:
        visitorUtils.forTime.isVisitorRecentlySignedIn(visitor)
    })

  const result = await openMatchPopUp(
    {
      title,
      description,
      note: visitorConstants.CHECK_IN_FORM.MODAL_ALLOW_RISK_NOTE,
      watchList,
      offenderList,
      isOffenderType: offenderList?.length > 0
    },
    visitor,
    data,
    modal
  )

  return result
}

const handleNonGuardian = async (visitor, data, modal) => {
  try {
    const validatePayload = visitorUtils.forPayload.nonGuardianCheckPayload(
      visitor,
      true
    )
    const {
      error,
      type,
      data: visitorGuardianData
    } = await visitorUtils.forChecks.checkNonGuardian(validatePayload)

    if (error) {
      data.results[visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN].message =
        visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_NON_GUARDIAN
      toast.error(visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_NON_GUARDIAN)
      return visitorConstants.DENIED_FLOW_STATUS.CANCEL
    }

    if (
      !error &&
      type ===
        visitorConstants.CHECK_IN_FORM.CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES
          .VALID_STUDENT_NON_GUARDIAN_DETAILS
    ) {
      return showVisitorNGVAllowDenyForm(
        visitorGuardianData?.students || [],
        visitor,
        data,
        modal
      )
    }

    data.results[visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN].message =
      visitorConstants.CHECK_IN_FORM.VALID_NON_GUARDIAN_STUDENT_DETAILS
    toast.info(
      visitorConstants.CHECK_IN_FORM.VALID_NON_GUARDIAN_STUDENT_DETAILS
    )
    return visitorConstants.DENIED_FLOW_STATUS.CONTINUE
  } catch (error) {
    data.results[visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN] = {
      status: "error",
      message: error.message
    }
    toast.error(visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_NON_GUARDIAN)
    return visitorConstants.DENIED_FLOW_STATUS.CANCEL
  }
}

const handlePreApproved = async (visitor, data, modal) => {
  const preApprovedCheckPayload =
    visitorUtils.forPayload.preApprovedCheckPayload(visitor)
  const { result: preApprovedResult, success: preApprovedSuccess } =
    await visitorUtils.forChecks.checkPreApproved(preApprovedCheckPayload)

  if (!preApprovedSuccess) {
    data.results[visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED].message =
      visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_PRE_APPROVED
    toast.error(visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_PRE_APPROVED)
    return visitorConstants.DENIED_FLOW_STATUS.CANCEL
  }

  if (preApprovedResult) {
    data.results[visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED].message =
      visitorConstants.CHECK_IN_FORM.PRE_APPROVED_VISITOR_FOUND
    return visitorConstants.DENIED_FLOW_STATUS.CONTINUE
  }

  return showNonPreApprovedVisitorAllowDenyForm(visitor, data, modal)
}

const handleHealthCheck = async (visitor, data, modal) => {
  const {
    hasDeniedLogAndAnswersData,
    hasAnswersFromDeniedLog,
    hasWrongAnswersFromDeniedLog
  } = visitorUtils.forDeniedFlow.getQuestionDataFromDeniedLog(visitor)

  if (hasDeniedLogAndAnswersData) {
    if (!hasAnswersFromDeniedLog) {
      data.results[visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION].message =
        visitorConstants.CHECK_IN_FORM.NO_HEALTH_CHECK_QUESTIONS_ANSWERED
      toast.info(
        visitorConstants.CHECK_IN_FORM.NO_HEALTH_CHECK_QUESTIONS_ANSWERED
      )
      return visitorConstants.DENIED_FLOW_STATUS.CANCEL
    }

    if (hasWrongAnswersFromDeniedLog) {
      return showHealthCheckFailedAllowDenyForm(visitor, data, modal)
    }

    data.results[visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION].message =
      visitorConstants.CHECK_IN_FORM.ALL_HEALTH_CHECK_QUESTIONS_CORRECT
    toast.info(
      visitorConstants.CHECK_IN_FORM.ALL_HEALTH_CHECK_QUESTIONS_CORRECT
    )
    return visitorConstants.DENIED_FLOW_STATUS.CONTINUE
  }

  const healthCheckPayload = visitorUtils.forPayload.healthCheckPayload(visitor)
  const { result: healthCheckData, success } =
    await visitorUtils.forChecks.checkHealthCheck(healthCheckPayload)

  if (!success) {
    data.results[visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION].message =
      visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_HEALTH_CHECK
    toast.error(visitorConstants.CHECK_IN_FORM.ERROR_CHECKING_HEALTH_CHECK)
    return visitorConstants.DENIED_FLOW_STATUS.CANCEL
  }

  const hasVisitorAnsweredAnyQuestion =
    healthCheckData
      ?.filter(
        (result) =>
          result?.question?.toString()?.length > 0 ||
          result?.spanishQuestion?.toString()?.length > 0
      )
      ?.some(
        (result) =>
          result?.visitorAnswer !== null &&
          [0, 1].includes(Number(result?.visitorAnswer))
      ) || false

  if (!hasVisitorAnsweredAnyQuestion) {
    data.results[visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION].message =
      visitorConstants.CHECK_IN_FORM.NO_HEALTH_CHECK_QUESTIONS_ANSWERED
    toast.info(
      visitorConstants.CHECK_IN_FORM.NO_HEALTH_CHECK_QUESTIONS_ANSWERED
    )
    return visitorConstants.DENIED_FLOW_STATUS.CANCEL
  }

  const hasWrongAnswers =
    healthCheckData
      ?.filter(
        (result) =>
          result?.question?.toString()?.length > 0 ||
          result?.spanishQuestion?.toString()?.length > 0
      )
      ?.some(
        (result) =>
          result?.visitorAnswer !== null &&
          Number(result?.visitorAnswer) !== Number(result?.correctAnswer)
      ) || false

  if (hasWrongAnswers) {
    return showHealthCheckFailedAllowDenyForm(visitor, data, modal)
  }

  data.results[visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION].message =
    visitorConstants.CHECK_IN_FORM.ALL_HEALTH_CHECK_QUESTIONS_CORRECT
  toast.info(visitorConstants.CHECK_IN_FORM.ALL_HEALTH_CHECK_QUESTIONS_CORRECT)
  return visitorConstants.DENIED_FLOW_STATUS.CONTINUE
}

const VisitorCheckSequence = [
  {
    type: visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST,
    checkFunction: handleOffenderWatchlist,
    isApplicable: () => !isPreApprovedVisitorSettingsEnabled()
  },
  {
    type: visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN,
    checkFunction: handleNonGuardian,
    isApplicable: (visitor) =>
      !isPreApprovedVisitorSettingsEnabled() &&
      visitorUtils.forReports.isStudentRelatedCheckIn(visitor)
  },
  {
    type: visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED,
    checkFunction: handlePreApproved,
    isApplicable: () => isPreApprovedVisitorSettingsEnabled()
  },
  {
    type: visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION,
    checkFunction: handleHealthCheck,
    isApplicable: () => isHealthScreeningSettingEnabled()
  }
]

const updateVisitorReportsAndReset = async (visitor, data, flowStatus) => {
  await updateVisitor(visitor, data, flowStatus)
  refreshVisitorReportsTable()
  return resetState(data)
}

const resetState = (data) => {
  data.isLoading = false
  data.currentCheckIndex = 0
  data.results = {
    [visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST]: {},
    [visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN]: {},
    [visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION]: {},
    [visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED]: {}
  }
  data.flowStatuses = []
}

const processNextCheck = async (visitor, data, modal) => {
  if (data.currentCheckIndex >= VisitorCheckSequence.length) {
    if (
      data.flowStatuses.includes(visitorConstants.DENIED_FLOW_STATUS.SIGNIN)
    ) {
      return await updateVisitorReportsAndReset(
        visitor,
        data,
        visitorConstants.DENIED_FLOW_STATUS.SIGNIN
      )
    } else {
      return resetState(data)
    }
  }

  const currentCheck = VisitorCheckSequence[data.currentCheckIndex]

  if (!currentCheck.isApplicable(visitor)) {
    data.currentCheckIndex++
    return await processNextCheck(visitor, data, modal)
  }

  const flowStatus = await currentCheck.checkFunction(visitor, data, modal)

  data.flowStatuses = [...data.flowStatuses, flowStatus]

  if (flowStatus === visitorConstants.DENIED_FLOW_STATUS.DENY) {
    return await updateVisitorReportsAndReset(
      visitor,
      data,
      visitorConstants.DENIED_FLOW_STATUS.DENY
    )
  }

  if (flowStatus === visitorConstants.DENIED_FLOW_STATUS.CANCEL) {
    return resetState(data)
  }

  if (
    [
      visitorConstants.DENIED_FLOW_STATUS.CONTINUE,
      visitorConstants.DENIED_FLOW_STATUS.SIGNIN
    ].includes(flowStatus)
  ) {
    data.currentCheckIndex++
    return await processNextCheck(visitor, data, modal)
  }
}

const startProcess = async (visitor, data, modal) => {
  const deniedLog = JSON.parse(visitor?.denied_log || "{}") || {}
  data.currentCheckIndex =
    visitorUtils.forDeniedFlow.getDeniedLogStartIndex(deniedLog)
  data.isLoading = true
  await processNextCheck(visitor, data, modal)
}

const useDeniedVisitorFlow = (visitor, modal) => {
  const data = reactive({
    isLoading: false,
    currentCheckIndex: 0,
    results: {
      [visitorConstants.DENIED_FLOW_KEYS.OFFENDER_WATCHLIST]: {},
      [visitorConstants.DENIED_FLOW_KEYS.NON_GUARDIAN]: {},
      [visitorConstants.DENIED_FLOW_KEYS.HEALTH_QUESTION]: {},
      [visitorConstants.DENIED_FLOW_KEYS.PRE_APPROVED]: {}
    },
    flowStatuses: []
  })

  return { data, startProcess: () => startProcess(visitor, data, modal) }
}

const refreshVisitorReportsTable = () => {
  return store.commit("visitorManage/SET_UPDATE_VISITOR_LOGS", true)
}

export default useDeniedVisitorFlow
