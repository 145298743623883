<template>
  <section class="v3-user-board-wrap" aria-label="User Information">
    <div class="v3-user-board">
      <div class="user-data d-flex align-items-center">
        <div class="v3-avatar">
          <img
            :src="user.avatar ? user.avatar : '/img/avatars/user-avatar.jpeg'"
            :alt="`Avatar for ${user.name}`"
          />
        </div>
        <div class="v3-user-info ps-2 flex-1">
          <b class="font-size-14 text-wrap" data-test-id="sidebar-user-names">{{
            user.name
          }}</b>
          <p class="m-0 font-size-12">{{ user.role.name }}</p>
        </div>
      </div>
      <nav aria-label="School Selection" class="py-2 px-3">
        <SidebarSchools> </SidebarSchools>
      </nav>
      <div
        class="user-actions d-flex align-items-center p-0"
        role="group"
        aria-label="User Actions"
      >
        <div class="flex-1 text-center py-2">
          <router-link
            data-test-id="sidebar-user-box"
            class="d-block"
            to="/user-settings"
            aria-label="User Settings"
          >
            <i class="ri-settings-3-line" aria-hidden="true"></i>
            <span class="sr-only">User Settings</span>
          </router-link>
        </div>
        <div class="flex-1 text-center d-flex justify-content-center py-2">
          <SoundBtn :user="user"></SoundBtn>
        </div>
        <div
          @click="logOut"
          data-test-id="sidebar-logout"
          class="flex-1 text-center py-2 v3-tooltip"
          role="button"
          tabindex="0"
          aria-label="Log Out"
        >
          <span class="tooltiptext">Log Out</span>
          <i class="ri-logout-circle-r-line" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SidebarSchools from "./SidebarSchools.vue"
import SoundBtn from "./SoundBtn.vue"
import { useStore } from "vuex"

export default {
  components: { SidebarSchools, SoundBtn },
  props: {
    user: Object
  },
  setup() {
    const store = useStore()

    const logOut = () => {
      store.dispatch("authentication/logOut")
    }
    return {
      logOut
    }
  }
}
</script>

<style></style>
