<template>
  <div class="visitor-user-profile-root">
    <div class="text-capitalize font-bold text-center">
      {{ user.type }}
    </div>
    <div class="w-100 d-flex justify-content-center align-content-center">
      <img
        :src="profileImage"
        alt="profile_image"
        class="visitor-user-profile-image"
      />
    </div>
    <div class="visitor-user-profile-scrollable-container">
      <div v-if="allowForAll" class="visitor-user-profile-attributes">
        <div>First name:</div>
        <div class="value">
          {{ firstName || "-" }}
        </div>
      </div>
      <div v-if="allowForAll" class="visitor-user-profile-attributes">
        <div>Last name:</div>
        <div class="value">
          {{ lastName || "-" }}
        </div>
      </div>
      <div v-if="isVisitorUser" class="visitor-user-profile-attributes">
        <div>Date of birth:</div>
        <div class="value">
          {{ dateOfBirth || "-" }}
        </div>
      </div>
      <div
        v-if="isWatchlistOrOffenderUser"
        class="visitor-user-profile-attributes"
      >
        <div>Age:</div>
        <div class="value">
          {{ age || "-" }}
        </div>
      </div>
      <div
        v-if="isWatchlistUser"
        class="visitor-user-profile-attributes"
      >
        <div>Reason:</div>
        <div class="value">
          {{ reason || "-" }}
        </div>
      </div>
      <div class="spacer"></div>
      <div
        v-if="isOffenderUser && !showMore"
        class="d-flex justify-content-center align-content-center"
      >
        <BaseButton rounded @click="toggleShowMore"> Show More </BaseButton>
      </div>
      <template v-else>
        <div v-if="isOffenderUser" class="visitor-divider-red"></div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>Gender:</div>
          <div class="value">
            {{ gender || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>Hair color:</div>
          <div class="value">
            {{ hairColor || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>Eye color:</div>
          <div class="value">
            {{ eyeColor || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>Height:</div>
          <div class="value">
            {{ height || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>Weight:</div>
          <div class="value">
            {{ weight || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-divider-red"></div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>Street:</div>
          <div class="value">
            {{ street || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>City:</div>
          <div class="value">
            {{ city || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>State:</div>
          <div class="value">
            {{ state || "-" }}
          </div>
        </div>
        <div v-if="isOffenderUser" class="visitor-divider-red"></div>
        <div v-if="isOffenderUser" class="visitor-user-profile-attributes">
          <div>Notes:</div>
          <div class="value">
            {{ notes || "-" }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import moment from "moment-timezone"
import { computed, ref } from "vue"

export default {
  name: "VisitorMatchUserDetail",
  components: {
    BaseButton
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    useDifferentKeyForVisitorDob: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isVisitorUser = computed(
      () =>
        props?.user?.type?.toString()?.trim()?.toLowerCase() ===
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_VISITOR
    )
    const isWatchlistUser = computed(
      () =>
        props?.user?.type?.toString()?.trim()?.toLowerCase() ===
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_WATCHLIST
    )
    const isOffenderUser = computed(
      () =>
        props?.user?.type?.toString()?.trim()?.toLowerCase() ===
        visitorConstants.CHECK_IN_FORM.MATCH_TYPE_OFFENDER
    )
    const isWatchlistOrOffenderUser = computed(
      () => isWatchlistUser.value || isOffenderUser.value
    )
    const allowForAll = computed(
      () => isVisitorUser.value || isWatchlistUser.value || isOffenderUser.value
    )
    const profileImage = computed(() => {
      if (isWatchlistUser.value) {
        return "/img/avatars/user-avatar.jpeg"
      }

      if (isVisitorUser.value) {
        return (
          props?.user?.local_profile_image?.toString()?.trim() ||
          props?.user?.profile?.profile_image_uri?.toString()?.trim() ||
          props?.user?.profile_image_thumbnail_uri?.toString()?.trim() ||
          props?.user?.profile?.profile_image_thumbnail_uri
            ?.toString()
            ?.trim() ||
          "/img/avatars/user-avatar.jpeg"
        )
      }

      /**
       * For Offender User, if image_uri is not present, then return default image
       * else return the pre_signed_url
       */
      if (props?.user?.image_uri) {
        return props?.user?.pre_signed_url?.toString()?.trim()
      } else {
        return "/img/avatars/user-avatar.jpeg"
      }
    })
    const firstName = props?.user?.first_name?.toString()?.trim() || ""
    const lastName = props?.user?.last_name?.toString()?.trim() || ""
    const dateOfBirth = props?.useDifferentKeyForVisitorDob
      ? moment(
          props?.user?.profile?.date_of_birth?.toString()?.trim(),
          "YYYY-MM-DD"
        ).format("MM-DD-YYYY")
      : moment(props?.user?.date_of_birth?.toString()?.trim()).format("MM-DD-YYYY") || ""
    const age = computed(() => {
      const age =
        props?.user?.age?.toString()?.trim() ||
        props?.user?.age1?.toString()?.trim() ||
        props?.user?.actual_age?.toString()?.trim() ||
        ""
      return age
    })
    const reason = isWatchlistUser.value
      ? props?.user?.reason?.toString()?.trim()
      : props?.user?.comments_description?.toString()?.trim() ||
        "" + " | " + props?.user?.description?.toString()?.trim() ||
        ""
    const gender = computed(() => {
      const map = {
        M: "Male",
        F: "Female"
      }
      return (
        map[props?.user?.sex?.toString()?.toUpperCase()?.trim() || ""] ||
        props?.user?.sex?.toString()?.toUpperCase()?.trim() ||
        ""
      )
    })
    const hairColor = props?.user?.hair?.toString()?.trim() || ""
    const eyeColor = props?.user?.eyes?.toString()?.trim() || ""
    const height = props?.user?.height?.toString().trim() || ""
    const weight = props?.user?.weight?.toString()?.trim() || ""
    const street =
      (
        (props?.user?.line1?.toString()?.trim() || "") +
        " " +
        (props?.user?.line2?.toString()?.trim() || "") +
        " " +
        (props?.user?.line3?.toString()?.trim() || "")
      )?.trim() || ""
    const city = props?.user?.city?.toString()?.trim() || ""
    const state = props?.user?.state?.toString()?.trim() || ""
    const notes = "He / She poses a threat to the community"

    const showMore = ref(false)

    const toggleShowMore = () => {
      showMore.value = !showMore.value
    }

    return {
      isVisitorUser,
      isOffenderUser,
      isWatchlistUser,
      isWatchlistOrOffenderUser,
      allowForAll,
      profileImage,
      firstName,
      lastName,
      dateOfBirth,
      age,
      reason,
      gender,
      hairColor,
      eyeColor,
      height,
      weight,
      street,
      city,
      state,
      notes,
      showMore,
      toggleShowMore
    }
  }
}
</script>

<style scoped>
.spacer {
  flex-grow: 1;
}

.font-bold {
  font-weight: bold;
}
</style>
