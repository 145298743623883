import * as v2Views from "../views/"
import * as v3Views from "../v3views/"
import SideBarLayout from "@/layouts/SideBarLayout.vue"
import visitorConfigs from "@/configs/visitorConfigs"
import { h, resolveComponent } from "vue"
import { isV3ViewVisible } from "@/helpers/featureFlags.js"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Forms from "../components/Forms"
import * as v3Forms from "../v3components/Forms"
import { shallowRef } from "vue"
// create Views object based on v3 feature flags rules in method isV3ViewVisible!!!!

const Views = {}
for (const key in v2Views) {
  if (Object.hasOwnProperty.call(v2Views, key)) {
    Views[key] = isV3ViewVisible(key) ? v3Views[key] : v2Views[key]
  }
}

const Routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
    component: SideBarLayout,
    meta: {
      requiredRoles: ["superadmin", "admin", "teacher", "staff"],
      helpButtonView: "dashboard_overview"
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: v3Views.DashboardOld,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"],
          classes: "dashboard-view"
        }
      },
      {
        path: "/history",
        name: "History",
        component: v3Views.History,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff"],
          helpButtonView: "pass_history"
        }
      },
      {
        path: "/teacher-pass",
        name: "Teacher Pass",
        component: v3Views.TeacherPass,
        meta: {
          helpButtonView: "teacher_pass_create",
          requiredRoles: ["superadmin", "admin", "teacher", "staff"]
        }
      },
      {
        path: "/appointments",
        name: "Appointment Pass",
        component: v3Views.AppointmentPass,
        meta: {
          studentName: "Appointments",
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"],
          helpButtonView: "appointment_pass_form",
          requiredModule: "Appointmentpass",
          routeActions: [
            {
              component: shallowRef(v3Forms.CreateAppointmentPassMultipleForm),

              title: "Appointment",
              modalTitle: "Create Appointment Pass",
              class: "solid-gradient",
              modalSize: "lg",
              exludeForRole: "student"
            }
          ]
        }
      },
      {
        path: "/out-of-office",
        name: "Out of Office",
        component: v3Views.OutOfOffice,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff"],
          helpButtonView: "office_out_form",
          routeActions: [
            {
              component: shallowRef(v3Forms.OutOfOfficeForm),
              title: "Out of Office",
              modalTitle: "Out of Office",
              modalSize: "lg",
              props: {
                isInView: true
              },
              class: "solid-gradient"
            }
          ]
        }
      },
      {
        path: "/id-card",
        name: "Digital ID",
        component: v3Views.DigitalID,
        meta: {
          requiredRoles: ["student"]
        }
      },

      {
        path: "/limits",
        name: "Limits",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        redirect: "/limits/limit-student-pass",
        meta: {
          requiredRoles: ["superadmin", "admin"]
        },
        children: [
          {
            path: "/limits/limit-student-pass",
            name: "Limit Student Pass",
            component: v3Views.LimitStudentPass,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "student_pass_limits",
              routeActions: [
                {
                  component: shallowRef(v3Forms.CreateLimitStudentPassForm),
                  title: "Limit",
                  props: {
                    isInView: true
                  },
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: "/limits/limit-location-max-cap",
            name: "Limit Location Max Cap",
            component: v3Views.LimitLocationMaxCap,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "limit_location_max_cap",
              routeActions: [
                {
                  component: shallowRef(v3Forms.CreateLimitLocationMaxCapForm),
                  title: "Limit",
                  modalTitle: "Limit Location Max Cap",
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: "/limits/limit-active-pass",
            name: "Limit Active Pass",
            component: v3Views.LimitActivePasses,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "active_pass_limit"
            }
          },
          {
            path: "/limits/limit-location-availability",
            name: "Limit Location Availability",
            component: v3Views.LimitLocationAvailability,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "limit_location_availability",
              routeActions: [
                {
                  component: shallowRef(
                    v3Forms.CreateLimitLocationAvailabilityForm
                  ),
                  title: "Availability",
                  props: {
                    isInView: true
                  },
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: "/limits/ab-polarity",
            name: "Contact Control",
            component: v3Views.ContactControl,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "contact_control",
              routeActions: [
                {
                  component: shallowRef(v3Forms.ContactControlMessageForm),
                  title: "Message",
                  modalTitle: "New Message",
                  icon: "ri-edit-box-line"
                },
                {
                  component: shallowRef(v3Forms.CreateContactControlForm),
                  title: "Contact Control",
                  modalTitle: "New Contact Control",
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: "/limits/pass-blocking",
            name: "Pass Blocking",
            component: v3Views.PassBlocking,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "pass_blocking",
              routeActions: [
                {
                  component: shallowRef(v3Forms.CreatePassBlockingForm),
                  title: "Block",
                  modalTitle: "Create Pass Blocking",
                  class: "solid-gradient"
                }
              ]
            }
          }
        ]
      },
      {
        path: "/system-settings",
        name: "System Settings",
        redirect: "/system-settings/rooms",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        meta: {
          requiredRoles: ["superadmin", "admin"]
        },
        children: [
          {
            path: "/system-settings/rooms",
            name: "Locations (rooms)",
            component: v3Views.Rooms,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "locations",
              routeActions: [
                {
                  component: shallowRef(v3Forms.CSVRoomForm),
                  title: "CSV",
                  modalTitle: "Import Locations by CSV upload",
                  icon: "ri-upload-line",
                  class: "full-rounded",
                  helpMark: "rooms_import"
                },
                {
                  component: shallowRef(v3Forms.NewRoomForm),
                  title: "Location",
                  modalTitle: "New Location",
                  class: "solid-gradient",
                  helpMark: "rooms_title"
                }
              ]
            }
          }
        ]
      },
      {
        path: "/users",
        name: "Users",
        redirect: "/users/main-users",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        meta: {
          requiredRoles: ["superadmin", "admin"]
        },
        children: [
          {
            path: "/users/main-users",
            name: "Main Users",
            component: v3Views.Users,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "main_users_menu"
            }
          }
        ]
      },
      {
        path: "/users/archived-users",
        name: "Archived Users",
        component: v3Views.ArchivedUsers,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"],
          helpButtonView: "archived_users_help"
        }
      },
      {
        path: "/users/substitute-users",
        name: "Substitute Users",
        component: v3Views.SubstituteUsers,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "form_add_substitute_pin_only",
          routeActions: [
            {
              component: shallowRef(v3Forms.CreateSubstituteForm),
              title: "New Substitute",
              class: "solid-gradient"
            }
          ]
        }
      },
      {
        path: "/user-settings",
        name: "Profile Settings",
        component: v3Views.UserSettings,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"],
          helpButtonView: "profile_settings_up_right"
        }
      },
      {
        path: "/passes/create",
        name: "Create Pass",
        component: v3Views.StudentPass,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"]
        }
      },
      {
        path: "/passes/activepass",
        name: "Active Pass",
        component: v3Views.ActivePass,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"]
        }
      },
      {
        path: "/tardy/consequences-level",
        name: "Student Consequences Ladder",
        component: v3Views.ConsequencesLadder,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"],
          requiredModule: "Tardy Management",
          routeDependency: "routerDependencies/isStudentLadderVisible"
        }
      },
      {
        path: "/tardy-management",
        name: "Tardy Management",
        redirect: "/tardy-management/reports",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff"],
          requiredModule: "Tardy Management",
          routeDependency: "routerDependencies/isVisibleTardyManagment"
        },
        children: [
          {
            path: "/tardy-management/adults",
            name: "Tardy Adults",
            component: v3Views.TardyManagementAdults,
            meta: {
              requiredRoles: ["superadmin", "admin", "teacher", "staff"],
              routeDependency: "routerDependencies/isVisibleTardyAdults",
              helpButtonView: "tardy_adults",
              classes: "main-bg",
              routeActions: [
                {
                  component: shallowRef(v3Forms.AssignAdultForm),
                  title: "Assign",
                  modalTitle: "Assign adults who can create LTS/LTC",
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: "/tardy-management/reports",
            name: "Tardy Reports",
            component: v3Views.TardyManagementReports,
            meta: {
              helpButtonView: "tardy_reports",
              requiredRoles: ["superadmin", "admin", "teacher", "staff"]
            }
          },
          {
            path: "/tardy-management/detentions-appointments",
            name: "Tardy Detention & Appointment List",
            component: v3Views.TardyDetentionsAndAppointments,
            meta: {
              helpButtonView: "tardy_detentions_and_appointments_list",
              requiredRoles: ["superadmin", "admin", "teacher", "staff"]
            }
          },
          {
            path: "/tardy-management/appointments",
            name: "Tardy Appointments",
            component: v3Views.TardyManagementAppointments,
            meta: {
              requiredRoles: ["superadmin", "admin", "teacher", "staff"]
            }
          },
          {
            path: "/tardy-management/detention-attendance",
            name: "Detention Attendance",
            component: v3Views.TardyManagementDetentionAttendance,
            meta: {
              requiredRoles: ["superadmin", "admin", "teacher", "staff"]
            }
          },
          {
            path: "/tardy-management/detention-calendar",
            name: "Detention Calendar",
            component: v3Views.TardyDetentionCalendar,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "tardy_detention_calendar",
              routeActions: [
                {
                  component: shallowRef(
                    v3Forms.CreateTardyDetentionCalendarForm
                  ),
                  title: "Schedule Detention",
                  modalTitle: "Schedule detention",
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: "/tardy-management/tardy-csv-upload",
            name: "Upload LTS and LTC files",
            component: v3Views.TardyCsvUpload,
            meta: {
              requiredRoles: ["superadmin", "admin", "teacher", "staff"],
              helpButtonView: "tardy_attendance",
              routeDependency: "routerDependencies/isVisibleTardyCSVUpload",
              routeActions: [
                {
                  component: shallowRef(v3Forms.CreateTardyCsvUploadForm),
                  title: "Upload tardy data by CSV file",
                  class: "solid-gradient"
                }
              ]
            }
          }
        ]
      },
      {
        path: visitorConfigs.pages.managementPage().path,
        name: visitorConfigs.pages.managementPage().name,
        redirect: visitorConfigs.pages.checkInLogsPage().path,
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        meta: {
          requiredRoles: visitorConfigs.pages.managementPage().roles,
          requiredModule: visitorConfigs.pages.managementPage().module,
          routeDependency: visitorConfigs.pages.managementPage().dependency
        },
        children: [
          {
            path: visitorConfigs.pages.checkInLogsPage().path,
            name: visitorConfigs.pages.checkInLogsPage().name,
            component: v3Views.VisitorCheckInsAndLogs,
            meta: {
              requiredRoles: visitorConfigs.pages.checkInLogsPage().roles,
              routeDependency:
                visitorConfigs.pages.checkInLogsPage().dependency,
              helpButtonView: visitorConfigs.pages.checkInLogsPage().helpKey,
              classes: "main-bg"
            }
          },
          {
            path: visitorConfigs.pages.printerPage().path,
            name: visitorConfigs.pages.printerPage().name,
            component: v3Views.VisitorPrinterSetup,
            meta: {
              requiredRoles: visitorConfigs.pages.printerPage().roles,
              routeDependency: visitorConfigs.pages.printerPage().dependency,
              helpButtonView: visitorConfigs.pages.printerPage().helpKey,
              classes: "main-bg"
            }
          }
        ]
      },
      {
        path: visitorConfigs.pages.settingsPage().path,
        name: visitorConfigs.pages.settingsPage().name,
        redirect: visitorConfigs.pages.adultsPage().path,
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        meta: {
          requiredRoles: visitorConfigs.pages.settingsPage().roles,
          requiredModule: visitorConfigs.pages.settingsPage().module,
          routeDependency: visitorConfigs.pages.settingsPage().dependency
        },
        children: [
          {
            path: visitorConfigs.pages.adultsPage().path,
            name: visitorConfigs.pages.adultsPage().name,
            component: v3Views.VisitorAdults,
            meta: {
              requiredRoles: visitorConfigs.pages.adultsPage().roles,
              routeDependency: visitorConfigs.pages.adultsPage().dependency,
              helpButtonView: visitorConfigs.pages.adultsPage().helpKey,
              classes: "main-bg",
              routeActions: [
                {
                  component: shallowRef(v3Forms.VisitorAdultAssignForm),
                  title: visitorConfigs.pages.adultsPage().action.assign.title,
                  modalTitle:
                    visitorConfigs.pages.adultsPage().action.assign.modalTitle,
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: visitorConfigs.pages.guardianStudentMatrixPage().path,
            name: visitorConfigs.pages.guardianStudentMatrixPage().name,
            component: v3Views.VisitorGuardianStudentMatrix,
            meta: {
              requiredRoles:
                visitorConfigs.pages.guardianStudentMatrixPage().roles,
              routeDependency:
                visitorConfigs.pages.guardianStudentMatrixPage().dependency,
              helpButtonView:
                visitorConfigs.pages.guardianStudentMatrixPage().helpKey,
              classes: "main-bg"
            }
          },
          {
            path: visitorConfigs.pages.listPage().path,
            name: visitorConfigs.pages.listPage().name,
            component: v3Views.VisitorListSettings,
            meta: {
              requiredRoles: visitorConfigs.pages.listPage().roles,
              routeDependency: visitorConfigs.pages.listPage().dependency,
              helpButtonView: visitorConfigs.pages.listPage().helpKey,
              classes: "main-bg"
            }
          },
          {
            path: visitorConfigs.pages.securityPage().path,
            name: visitorConfigs.pages.securityPage().name,
            component: v3Views.VisitorSecuritySettings,
            meta: {
              requiredRoles: visitorConfigs.pages.securityPage().roles,
              routeDependency: visitorConfigs.pages.securityPage().dependency,
              helpButtonView: visitorConfigs.pages.securityPage().helpKey,
              classes: "main-bg"
            }
          },
          {
            path: visitorConfigs.pages.kioskPage().path,
            name: visitorConfigs.pages.kioskPage().name,
            component: v3Views.VisitorKioskSettings,
            meta: {
              requiredRoles: visitorConfigs.pages.kioskPage().roles,
              routeDependency: visitorConfigs.pages.kioskPage().dependency,
              helpButtonView: visitorConfigs.pages.kioskPage().helpKey,
              classes: "main-bg"
            }
          },
          {
            path: visitorConfigs.pages.generalPage().path,
            name: visitorConfigs.pages.generalPage().name,
            component: v3Views.VisitorGeneralSettings,
            meta: {
              requiredRoles: visitorConfigs.pages.generalPage().roles,
              routeDependency: visitorConfigs.pages.generalPage().dependency,
              helpButtonView: visitorConfigs.pages.generalPage().helpKey,
              classes: "main-bg"
            }
          }
        ]
      },
      {
        path: "/tardy-settings",
        name: "Tardy Settings",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        redirect: "/tardy-settings/appointments",
        meta: {
          requiredRoles: ["superadmin", "admin"],
          requiredModule: "Tardy Management"
        },
        children: [
          {
            path: "/tardy-settings/setup-detention",
            name: "Tardy Detention(s)",
            component: v3Views.TardyDetentions,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "tardy_detentions_assigned_staff",
              routeActions: [
                {
                  component: shallowRef(v3Forms.CreateTardyDetentionForm),
                  title: "Detention",
                  modalTitle: "Add new detention",
                  class: "solid-gradient"
                }
              ]
            }
          },
          {
            path: "/tardy-settings/consequences",
            name: "Consequences Ladder",
            component: v3Views.TardyConsequencesLadder,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "consequences_ladder",
              routeActions: [
                {
                  component: shallowRef(v3Forms.CreateConsequencesLaddeForm),
                  title: "Ladder",
                  modalTitle: "Add new consequences ladder",
                  class: "solid-gradient"
                }
              ]
            }
          }
        ]
      },
      {
        path: "/training",
        name: "Training",
        redirect: "/training/video-tutorial",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"]
        },
        children: [
          {
            path: "/training/documents",
            name: "Documents",
            component: v3Views.Documents,
            meta: {
              requiredRoles: [
                "superadmin",
                "admin",
                "teacher",
                "staff",
                "student"
              ],
              helpButtonView: "training_documents"
            }
          },
          {
            path: "/training/videos",
            name: "Training Videos",
            component: v3Views.TrainingVideos,
            meta: {
              requiredRoles: [
                "superadmin",
                "admin",
                "teacher",
                "staff",
                "student"
              ],
              helpButtonView: "training_videos"
            }
          }
        ]
      },
      {
        path: "/updates",
        name: "Updates",
        component: v3Views.Updates,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"],
          helpButtonView: "updates"
        }
      },
      {
        path: "/kiosk",
        name: "Kiosk",
        component: v3Views.Kiosks,
        meta: {
          requiredRoles: ["superadmin", "admin", "teacher", "staff", "student"],
          requiredModule: "Kiosk",
          classes: "kiosks-view",
          helpButtonView: "form_location_kiosk",
          routeActions: [
            {
              component: shallowRef(v3Forms.CreateKioskForm),
              title: "Kiosk",
              modalTitle: "Create Kiosk",
              class: "solid-gradient"
            }
          ]
        }
      },
      {
        path: "/help-desk",
        name: "Help Desk",
        component: v3Views.HelpDeskPortal,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "help_desk"
        }
      },
      {
        path: "/reports",
        name: "Reports",
        redirect: "/reports/summary-reports",
        component: {
          render() {
            return h(resolveComponent("router-view"))
          }
        },
        meta: {
          requiredRoles: ["superadmin", "admin"]
        },
        children: [
          {
            path: "/reports/summary-reports",
            name: "Summary Reports",
            component: v3Views.SummaryReports,
            meta: {
              helpButtonView: "summary_reports",
              requiredRoles: ["superadmin", "admin"]
            }
          },
          {
            path: "/reports/meet-up-report",
            name: "Meet-up Report",
            component: v3Views.ContactTracing,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "contact_tracing_meet_up_report"
            }
          },
          {
            path: "/reports/pass-totals",
            name: "Pass Totals",
            component: v3Views.PassTotals,
            meta: {
              requiredRoles: ["superadmin", "admin"],
              helpButtonView: "pass_total_reports"
            }
          }
        ]
      },
      {
        path: "/system-settings/pass-transparency",
        name: "Pass Transparency",
        component: v3Views.PassTransparency,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "pass_transparency",
          routeActions: [
            {
              actionVisibleDependency: "passes/transparencyStatus",
              component: shallowRef(v3Forms.PassTransparencyForm),
              title: "Allow",

              class: "solid-gradient"
            }
          ]
        }
      },
      {
        path: "/system-settings/auto-pass",
        name: "Auto Pass",
        component: v3Views.AutoPass,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "auto_pass",
          routeActions: [
            {
              component: shallowRef(v3Forms.AutoPassFormModal),
              title: "Location",
              modalTitle: "Select locations (rooms) for Auto pass",
              class: "solid-gradient"
            }
          ]
        }
      },
      {
        path: "/system-settings/pass-times",
        name: "Pass Times",
        component: v3Views.PassTimes,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "pass_times"
        }
      },
      {
        path: "/modules",
        name: "Modules",
        component: v3Views.ModulesList,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "modules"
        }
      },
      {
        path: "/file-uploads",
        name: "File Uploads",
        component: v3Views.FileUploads,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "file_uploads"
        }
      },
      {
        path: "/system-settings/periods",
        name: "Period schedules",
        component: v3Views.Periods,
        meta: {
          requiredRoles: ["superadmin", "admin"],
          helpButtonView: "periods_create",
          routeActions: [
            {
              component: shallowRef(v3Forms.CreatePeriodForm),
              title: "Create",
              modalTitle: "New Period",
              modalSize: "sm",
              class: "solid-gradient"
            }
          ]
        }
      },
      {
        path: "/form-components-examples",
        name: "FromComponentsExamples",
        component: v3Views.FormComponentsExamples,
        meta: {
          requiredRoles: ["superadmin", "admin"]
        }
      }
    ]
  },
  {
    path: "/login/:source?/:type?",
    name: "Login",
    component: Views.Login
  },
  {
    path: "/glogin",
    name: "glogin",
    component: Views.Login
  },
  {
    path: "/ologin",
    name: "ologin",
    component: Views.Login
  },
  {
    path: "/clogin",
    name: "clogin",
    component: Views.Login
  },
  {
    path: "/classlinklogin",
    name: "classlinklogin",
    component: Views.Login
  },
  {
    path: "/classlinkoauth",
    name: "classlinkoauth",
    redirect: "/login/web/classlink"
  },
  {
    path: "/clever/oauth",
    name: "cleveroauth",
    redirect: "/login/web/clever"
  },
  {
    path: "/gg4l",
    name: "gg4l",
    component: Views.Login
  },
  {
    path: "/forgotten-password",
    name: "Forgotten Password",
    component: Views.ForgottenPassword
  },
  {
    path: "/password/reset",
    name: "Reset Password",
    component: Views.ResetPassword
  },
  {
    path: "/sso/verifyEmail",
    name: "verifyEmail",
    component: v3Views.VerifyEmail
  },
  {
    path: "/kiosk/login",
    name: "Kiosk Login",
    component: v3Views.KioskLogin
  },
  {
    path: "/kiosk/launch",
    name: "Kiosk System",
    component: v3Views.KioskLaunch
  },
  {
    path: "/locked",
    name: "locked",
    component: Views.Locked
  },
  {
    path: "/acknowledged-pass",
    name: "acknowledgedpass",
    component: Views.AcknowledgedPass
  },
  {
    path: "/passdetail",
    name: "passdetails",
    redirect: "/dashboard"
  },
  {
    path: "/404",
    name: "NotFound",
    component: v3Views.NotFound
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: "/404"
  }
]

export default Routes
