import visitorConstants from "@/constants/visitorConstants"
import store from "@/store"
import moment from "moment-timezone"

const forTime = {
  isVisitorRecentlySignedIn: (visitor) => {
    const signedInDay = moment
      .utc(visitor?.signed_in_date, "YYYY-MM-DD HH:mm:ss")
      .local()
      .startOf("day")
    const currentDay = moment().local().startOf("day")
    const diffInDays = currentDay.diff(signedInDay, "days")
    return diffInDays < 1
  }
}

const forPayload = {
  watchlistOffenderCheckPayload: (visitor) => ({
    firstName: visitor?.first_name,
    lastName: visitor?.last_name,
    dateOfBirth: visitor?.profile?.date_of_birth
  }),
  healthCheckPayload: (visitor) => {
    return {
      visitorId: visitor?.visitor_id || "",
      visitId: visitor?.visit_id || ""
    }
  },
  preApprovedCheckPayload: (visitor) => {
    return {
      firstName: visitor?.first_name,
      lastName: visitor?.last_name,
      dateOfBirth: visitor?.profile?.date_of_birth
    }
  },
  nonGuardianCheckPayload: (visitor, isFromDeniedFlow = false) => {
    const studentPayload =
      visitor?.students?.split("|")?.map((item) => {
        const [firstName, lastName] = item?.split(" ") || ["", ""]
        return {
          first_name: firstName || "",
          last_name: lastName || ""
        }
      }) || []
    const payload = {
      visitorId: visitor?.visitor_id || "",
      firstName: visitor?.first_name,
      lastName: visitor?.last_name,
      students: studentPayload
    }
    if (isFromDeniedFlow) {
      payload.fromDeniedFlow = true
    }
    return payload
  }
}

const forChecks = {
  checkOffender: async (payload) => {
    try {
      const result = await store.dispatch(
        "visitorManage/checkOffender",
        payload
      )
      return { result, success: true }
    } catch (error) {
      return { result: { error }, success: false }
    }
  },
  checkWatchlist: async (payload) => {
    try {
      const result = await store.dispatch(
        "visitorManage/checkWatchlist",
        payload
      )
      return { result, success: true }
    } catch (error) {
      return { result: { error }, success: false }
    }
  },
  checkHealthCheck: async (payload) => {
    try {
      const result = await store.dispatch(
        "visitorManage/getHealthCheckResults",
        payload
      )
      return { result, success: true }
    } catch (error) {
      return { result: { error }, success: false }
    }
  },
  checkPreApproved: async (payload) => {
    try {
      const result = await store.dispatch(
        "visitorManage/checkPreApprovedVisitor",
        payload
      )
      return { result, success: true }
    } catch (error) {
      return { result: { error }, success: false }
    }
  },
  checkNonGuardian: async (payload) => {
    const { error, type, data, message } = await store.dispatch(
      "visitorManage/checkVisitorGuardian",
      payload
    )
    return {
      error,
      type,
      data,
      message
    }
  }
}

const forSettings = {
  isSettingsEnabled: (school, settingId) => {
    const setting = school?.data?.building_settings?.find(
      (setting) => setting?.settings_id === settingId
    )
    return setting?.value === "true" || false
  }
}

const forReports = {
  isStudentRelatedCheckIn: (visitor) => {
    const studentLookUpValues = ["student pick up", "student drop off"]
    const lookupValue = visitor?.lookup_value?.trim()?.toLowerCase()
    const reasonDescription = visitor?.reason_description?.trim()?.toLowerCase()
    return (
      studentLookUpValues.includes(lookupValue) ||
      studentLookUpValues.includes(reasonDescription)
    )
  }
}

const forDeniedFlow = {
  getQuestionDataFromDeniedLog: (visitor) => {
    const deniedLog = JSON.parse(visitor?.denied_log || "{}") || {}
    const answers = Object.values(deniedLog?.health_screening_qna || {}) || []
    const wrongAnswers =
      answers?.filter(
        (item) =>
          item?.question?.toString()?.length > 0 && item?.is_correct === false
      ) || []
    return {
      hasDeniedLogAndAnswersData:
        Boolean(JSON.parse(visitor?.denied_log)) && answers?.length > 0,
      hasAnswersFromDeniedLog: answers?.length > 0,
      hasWrongAnswersFromDeniedLog: wrongAnswers?.length > 0
    }
  },
  getExceptionMessageFromResults: (data) => {
    const messages =
      Object.values(data?.results)
        ?.map((item) => item?.message)
        ?.filter((message) => message?.toString()?.trim()?.length > 0) || []
    return messages.join(" | ")
  },
  getDeniedLogStartIndex: (deniedLog) => {
    if (Boolean(deniedLog?.offender) || Boolean(deniedLog?.watchlist)) {
      return 0
    } else if (deniedLog?.non_guardian) {
      return 1
    } else if (deniedLog?.pre_approved) {
      return 2
    } else if (deniedLog?.health_screening) {
      return 3
    }
    return 0
  },
  getOffenderWatchlistModalContent: ({
    isBoth,
    isOffenderType,
    isRecentlySignedIn
  }) => {
    const title = isBoth
      ? visitorConstants.CHECK_IN_FORM.MODAL_TITLE_WATCHLIST_OR_OFFENDER_MATCH
      : isOffenderType
        ? visitorConstants.CHECK_IN_FORM.MODAL_TITLE_OFFENDER_MATCH
        : visitorConstants.CHECK_IN_FORM.MODAL_TITLE_WATCHLIST_MATCH
    const description = isRecentlySignedIn
      ? isBoth
        ? visitorConstants.CHECK_IN_FORM
            .MODAL_DESCRIPTION_WATCHLIST_OR_OFFENDER_MATCH_ALLOW
        : isOffenderType
          ? visitorConstants.CHECK_IN_FORM
              .MODAL_DESCRIPTION_OFFENDER_MATCH_ALLOW
          : visitorConstants.CHECK_IN_FORM
              .MODAL_DESCRIPTION_WATCHLIST_MATCH_ALLOW
      : visitorConstants.CHECK_IN_FORM.MODAL_USER_DENIED_FOR_POSSIBLE_MATCH
    return { title, description }
  }
}

export default {
  forTime,
  forPayload,
  forChecks,
  forSettings,
  forDeniedFlow,
  forReports
}
