<template>
  <div class="d-flex flex-column gap-4">
    <h3 class="text-center text-red -mt-2">
      {{ isInVisitorDeniedFlow ? popUp.reAllowTitle : popUp.title }}
    </h3>
    <form class="d-flex flex-column gap-4" @submit.prevent="onSubmit">
      <div class="d-flex flex-column gap-1">
        <Label>
          {{
            isInVisitorDeniedFlow ? popUp.reAllowInputLabel : popUp.inputLabel
          }}
        </Label>
        <InputField
          placeholder="Enter the reason"
          v-model="form.reason"
          :invalid-feedback="errors.reason"
        />
      </div>
      <div class="d-flex justify-content-center align-content-center gap-4">
        <BaseButton type="button" rounded @click="onCancel">Cancel</BaseButton>
        <BaseButton type="submit" rounded solid>Submit</BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import visitorConstants from "@/constants/visitorConstants"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { computed, reactive } from "vue"

const staticData = {
  "health-check": {
    title:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_HEALTH_CHECK_FAILED_EXCEPTION,
    inputLabel: "Reason for custom question failed exception",
    reAllowTitle:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_HEALTH_CHECK_ALLOW_EXCEPTION,
    reAllowInputLabel: "Reason for re-allowing custom question failed exception"
  },
  "non-guardian-visitor": {
    title:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_NON_GUARDIAN_VISITOR_EXCEPTION,
    inputLabel: "Reason for non guardian exception",
    reAllowTitle:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_NON_GUARDIAN_ALLOW_EXCEPTION,
    reAllowInputLabel: "Reason for re-allowing non guardian exception"
  },
  "non-pre-approved-visitor": {
    title:
      visitorConstants.CHECK_IN_FORM
        .MODAL_TITLE_NON_PRE_APPROVED_VISITOR_EXCEPTION,
    inputLabel: "Reason for non pre-set visitor exception",
    reAllowTitle:
      visitorConstants.CHECK_IN_FORM
        .MODAL_TITLE_NON_PRE_APPROVED_ALLOW_EXCEPTION,
    reAllowInputLabel: "Reason for re-allowing non pre-set visitor exception"
  },
  watchlist: {
    title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_EXCEPTION_EXCEPTION,
    inputLabel: "Reason for watch list exception",
    reAllowTitle:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_WATCHLIST_ALLOW_EXCEPTION,
    reAllowInputLabel: "Reason for re-allowing watch list exception"
  },
  offender: {
    title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_OFFENDER_EXCEPTION,
    inputLabel: "Reason for offender exception",
    reAllowTitle:
      visitorConstants.CHECK_IN_FORM.MODAL_TITLE_OFFENDER_ALLOW_EXCEPTION,
    reAllowInputLabel: "Reason for re-allowing offender exception"
  }
}

export default {
  name: "VisitorExceptionReasonPopUp",
  components: {
    BaseButton,
    Label,
    InputField
  },
  props: {
    type: {
      type: String,
      default: "health-check",
      validator: (value) => Object.keys(staticData).includes(value)
    },
    signIn: {
      type: Function,
      default: () => ({})
    },
    deny: {
      type: Function,
      default: () => ({})
    },
    isInVisitorDeniedFlow: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const form = reactive({
      reason: ""
    })

    const rules = {
      reason: {
        required: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          required
        )
      }
    }

    const errors = computed(() => ({
      reason: v$?.value?.reason?.$error
        ? v$?.value?.reason?.$errors.map((error) => error.$message).join(", ")
        : ""
    }))

    const v$ = useVuelidate(rules, form)

    const popUp = computed(() => staticData[props.type])

    const onSubmit = async () => {
      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        return
      }

      props.signIn(form.reason)
      emit("cancel")
    }

    const onCancel = () => {
      props.deny()
      emit("cancel")
    }

    return {
      v$,
      popUp,
      errors,
      form,
      onSubmit,
      onCancel
    }
  }
}
</script>

<style scoped>
.-mt-2 {
  margin-top: -2rem;
}

.text-red {
  color: red;
}
</style>
