import axios from "axios"
import moment from "moment-timezone"
import helpers from "@/helpers/index.js"
import { apiV6nocache } from "@/store/modules/axiousWithCache.js"
import store from "@/store/index"

const nextSevenDays = function () {
  const today = moment().format("MM/DD/YYYY")
  const nextSevenDays = moment().add(7, "days").format("MM/DD/YYYY")
  return [today.toString(), nextSevenDays.toString()]
}
const beyondSevenDays = function () {
  const nextOneYear = moment().add(1, "year").format("MM/DD/YYYY")
  return [
    moment().add(8, "days").format("MM/DD/YYYY").toString(),
    nextOneYear.toString()
  ]
}
const state = {
  appointmentPasses: new Map(),
  editablePass: null,
  commentablePass: null,
  activeTab: "nextSevenDays",
  search_query: "",
  sort: null,
  quickFilter: { label: "All Passes", value: "" },
  passType: [{ label: "All Types", value: "" }],
  betweenDates: nextSevenDays(),
  recurrence: false,
  areAppointmentPassesLoading: false,
  pagination: {
    activePage: 1,
    total: 0,
    pages: 0,
    per_page: { label: "25", value: 25 }
  }
}

const getters = {
  appointmentPasses(state) {
    const allAptPasses = Array.from(state.appointmentPasses.values())
    let filteredAptPasses = []
    const type = store.getters["dashboardTable/getType"]
    //if we are in apt data table and not in the dashboard
    if (type === "appointment") {
      const user = store.getters["authentication/user"]
      let quickFilter = store.getters["adultAptPass/quickFilter"]
      quickFilter = quickFilter.value

      const filters = {
        just_my_created: (pass) =>
          pass.created_by_user ? pass.created_by_user.id == user.id : false,
        from_me: (pass) => pass.from.id == user.id,
        to_me: (pass) => pass.to.id == user.id,
        to_and_from_others: (pass) =>
          pass.from.id != user.id && pass.to.id != user.id
      }
      if (quickFilter === "") {
        filteredAptPasses = [...allAptPasses]
      } else {
        Object.keys(filters).forEach((key) => {
          if (quickFilter === key) {
            filteredAptPasses = [
              ...filteredAptPasses,
              ...allAptPasses.filter(filters[key])
            ]
          }
        })
      }
    } else {
      filteredAptPasses = allAptPasses.filter((apt) =>
        helpers.filterAptPassesByTab(apt)
      )
    }
    return filteredAptPasses
  },
  paginationBasedOnAppPasses(state, getters) {
    const filteredAptPasses = getters?.appointmentPasses || []
    return {
      ...state.pagination,
      total: filteredAptPasses?.length || 0,
      pages: Math.ceil(
        (filteredAptPasses?.length || 0) / state.pagination?.per_page?.value
      ),
      from:
        ((state.pagination?.activePage || 1) - 1) *
          (state.pagination?.per_page?.value || 1) +
        1,
      to:
        (state.pagination?.activePage || 1) *
        (state.pagination?.per_page?.value || 1)
    }
  },
  editablePass(state) {
    return state.editablePass
  },
  commentablePass(state) {
    return state.commentablePass
  },
  activeTab(state) {
    return state.activeTab
  },
  passType(state) {
    return state.passType
  },
  quickFilter(state) {
    return state.quickFilter
  },
  searchQuery(state) {
    return state.search_query
  },
  pagination(state) {
    return state.pagination
  },
  areAppointmentPassesLoading(state) {
    return state.areAppointmentPassesLoading
  }
}

const mutations = {
  SET_ACTIVE_TAB(state, payload) {
    state.activeTab = payload
    if ("nextSevenDays" == payload) {
      state.betweenDates = nextSevenDays()
      state.recurrence = false
    } else {
      state.betweenDates = beyondSevenDays()
      state.recurrence = true
    }
    state.pagination.activePage = 1
    state.quickFilter = { label: "All Passes", value: "" }
    this.dispatch("adultAptPass/getAppointmentPasses")
  },
  SET_BETWEEN_DATES(state, payload) {
    state.betweenDates = payload
  },
  SET_RECURRENCE(state, payload) {
    state.recurrence = payload
  },
  SET_QUICK_FILTER(state, payload) {
    state.quickFilter = payload
    state.pagination.activePage = 1
  },
  SET_PASS_TYPE(state, payload) {
    if (payload.value == "") {
      state.passType = [{ label: "All Types", value: "" }]
    } else {
      if (!state.passType.map((e) => e.value).includes(payload.value)) {
        state.passType = [...state.passType, payload]
      } else {
        state.passType = state.passType.filter((e) => e.value != payload.value)
      }
    }
    state.pagination.activePage = 1
  },
  SET_SEARCH_QUERY(state, payload) {
    state.search_query = payload
    state.pagination.activePage = 1
  },
  SET_SORT_BY(state, payload) {
    state.sort = payload
    state.pagination.activePage = 1
    this.dispatch("adultAptPass/getAppointmentPasses")
  },
  SET_APPOINTMENT_PASSES(state, passesCollection) {
    state.appointmentPasses = new Map()
    passesCollection.forEach((passObj) => {
      passObj = helpers.aptPassStatus(passObj)
      state.appointmentPasses.set(passObj.id, passObj)
    })
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  SET_ACTIVE_PAGE(state, payload) {
    state.pagination.activePage = payload
    this.dispatch("adultAptPass/getAppointmentPasses")
  },
  SET_PER_PAGE(state, payload) {
    if (payload.value == 75) {
      payload = { label: "All entries", value: 75 }
    }
    state.pagination.activePage = 1
    state.pagination.per_page = payload
    this.dispatch("adultAptPass/getAppointmentPasses")
  },
  SET_PAGINATION(state, payload) {
    if (payload.per_page.value == 75) {
      payload.per_page = { label: "All entries", value: 75 }
    }
    state.pagination = payload
  },
  SET_EDITABLE_PASS(state, pass) {
    state.editablePass = pass
  },
  SET_COMMENTABLE_PASS(state, pass) {
    state.commentablePass = pass
  },
  PUSH_APPOINTMENT_PASSES(state, passesCollection) {
    passesCollection.forEach((passObj) => {
      passObj = helpers.aptPassStatus(passObj)
      state.appointmentPasses.set(passObj.id, passObj)
    })
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  PUSH_APPOINTMENT_PASS(state, passObj) {
    const isActiveDashboardUpdate =
      store.getters["dashboardTable/isActiveDashboardUpdate"]

    if (isActiveDashboardUpdate) {
      state.pagination.activePage = 1
    }
    passObj = helpers.aptPassStatus(passObj)
    state.appointmentPasses.set(passObj.id, passObj)
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  UPDATE_APP_PASS(state, passObj) {
    const isActiveDashboardUpdate =
      store.getters["dashboardTable/isActiveDashboardUpdate"]

    if (!isActiveDashboardUpdate) {
      if (state.appointmentPasses.has(passObj.id)) {
        passObj = helpers.aptPassStatus(passObj)
        state.appointmentPasses.set(passObj.id, passObj)
      }
    } else {
      passObj = helpers.aptPassStatus(passObj)
      state.appointmentPasses.set(passObj.id, passObj)
    }
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  DELETE_APTS_BY_ID(context, ids) {
    ids.forEach((id) => state.appointmentPasses.delete(id))
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  SET_ARE_APPOINTMENT_PASSES_LOADING(state, value) {
    state.areAppointmentPassesLoading = value
  }
}

const actions = {
  createAppointmentPass(context, appointments) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/passes/appointments`, appointments)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAppointmentPasses(context) {
    context.commit("SET_ARE_APPOINTMENT_PASSES_LOADING", true)
    let params = {
      page: context.state.pagination.activePage,
      per_page: context.state.pagination.per_page.value,
      search_query: context.state.search_query,
      sort: context.state.sort,
      quick_filter: context.state.quickFilter.value
        ? context.state.quickFilter.value
        : null,
      pass_type: context.state.passType
        .map((e) => e.value)
        .filter((e) => e != ""),
      between_dates: context.state.betweenDates,
      recurrence: context.state.recurrence
    }
    params = Object.entries(params).reduce(
      (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
      {}
    )
    return new Promise((resolve, reject) => {
      apiV6nocache
        .get(`/admin/passes/appointments/filter`, {
          params: params
        })
        .then((response) => {
          let data = response.data.data
          const meta = response.data.meta
          if (data) {
            data = data.map((e) => helpers.aptPassStatus(e))
            if (meta.per_page == 75 && meta.current_page > 1) {
              context.commit("PUSH_APPOINTMENT_PASSES", data)
            } else {
              context.commit("SET_APPOINTMENT_PASSES", data)
            }
            if (meta) {
              context.commit("SET_PAGINATION", {
                total: meta.total,
                activePage: meta.current_page,
                pages: meta.last_page,
                per_page: { label: `${meta.per_page}`, value: meta.per_page },
                from: meta.from,
                to: meta.to
              })
            }
            resolve(response)
          }
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          context.commit("SET_ARE_APPOINTMENT_PASSES_LOADING", false)
        })
    })
  },
  getCSVAppointmentPasses(context, reqParams = {}) {
    const params = {
      page: context.state.pagination.activePage,
      per_page: [25, 50, 100].includes(context.state.pagination.per_page.value)
        ? context.state.pagination.per_page.value
        : "all",
      search_query: context.state.search_query,
      sort: context.state.sort,
      quick_filter: context.state.quickFilter.value,
      pass_type: context.state.passType
        .map((e) => e.value)
        .filter((e) => e != ""),
      between_dates: context.state.betweenDates,
      recurrence: context.state.recurrence,
      ...reqParams
    }
    return new Promise((resolve, reject) => {
      apiV6nocache
        .get(`/admin/passes/appointments/csv`, {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  confirmAppointmentPass(context, passID) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/passes/appointments/${passID}/confirm`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  acknowledgeAppointmentPass(context, passID) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/passes/appointments/${passID}/acknowledge`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  cancelAppointmentPass(context, pass) {
    return new Promise((resolve, reject) => {
      const fromRecurrence = pass.fromRecurrence ? "?from_recurrence" : ""
      const passMessage = pass.message || null
      axios
        .put(
          `/admin/passes/appointments/${pass.id}/cancel${fromRecurrence}`,
          passMessage
        )
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  cancelRecurrenceAppointmentPass(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/passes/appointments/recurrence/${pass.id}/cancel`, {
          action: pass.action
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  editAppointmentPass(context, pass) {
    return new Promise((resolve, reject) => {
      apiV6nocache
        .put(`/admin/passes/appointments/${pass.id}`, pass.data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  editRecurrenceAppPass(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/passes/appointments/recurrence/${pass.id}`, pass.data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  acknowledgeAppointmentPassEmail(context, token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/passes/appointments/acknowledge-mail?token=${token}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getInfoForExistApp(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/users/student-limit-info/${params.studentID}`, {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
