<template>
  <div class="d-flex flex-column gap-4">
    <h3 class="text-center text-red -mt-1">
      Custom question
      <span v-if="visitor?.health_check_status?.toLowerCase()?.length > 0">
        {{ visitor?.health_check_status?.toLowerCase() + "ed" }}
      </span>
    </h3>

    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- WHEN RESULT LIST IS LOADING -->
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-content-center gap-2"
    >
      <CSpinner color="primary" />
    </div>
    <!-- WHEN RESULT LIST IS LOADING -->

    <!-- RESULT LIST -->
    <div v-else-if="hasWrongAnswers" class="d-flex flex-column gap-4">
      <div class="text-center">Custom answers</div>
      <div class="visitor-health-check-result-list">
        <!-- WRONG ANSWERS -->
        <p
          v-for="(result, index) in wrongHealthCheckAnswers"
          :key="index"
          class="visitor-health-check-result"
        >
          <span>{{ result.question }}</span>
          <span class="font-bold">
            {{ Number(result.visitorAnswer) === 1 ? "Yes" : "No" }}
          </span>
        </p>
      </div>
    </div>
    <!-- RESULT LIST -->

    <!-- WHEN RESULT LIST IS EMPTY -->
    <div v-else class="text-center">
      No wrong custom question answers available
    </div>
    <!-- WHEN RESULT LIST IS EMPTY -->

    <div
      v-if="showAllowAndDenyActions"
      class="d-flex justify-content-center align-content-center gap-4"
    >
      <BaseButton rounded @click="onAllow">Allow entry</BaseButton>
      <BaseButton rounded solid @click="onDeny">Deny entry</BaseButton>
    </div>

    <div
      v-if="showCancelAndProceedActions"
      class="d-flex flex-column align-items-center gap-4"
    >
      <p class="visitor-info-note text-center">
        {{ visitorConstants.CHECK_IN_FORM.VISITOR_NOT_RECENT_CHECK_IN_MESSAGE }}
      </p>
      <div class="d-flex justify-content-center align-content-center gap-4">
        <BaseButton rounded @click="onCancel">Cancel</BaseButton>
        <BaseButton rounded solid @click="onProceed">
          Show Next If Exists
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import { computed, onMounted, reactive, ref } from "vue"
import { useStore } from "vuex"
import visitorConstants from "@/constants/visitorConstants"
export default {
  name: "VisitorHealthCheckForm",
  components: {
    BaseButton,
    VisitorErrorHandler
  },
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    },
    isInVisitorDeniedFlow: {
      type: Boolean,
      default: false
    },
    isVisitorRecentlySignedIn: {
      type: Boolean,
      default: false
    },
    allow: {
      type: Function,
      default: () => ({})
    },
    deny: {
      type: Function,
      default: () => ({})
    },
    cancel: {
      type: Function,
      default: () => ({})
    },
    proceed: {
      type: Function,
      default: () => ({})
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()

    const isLoading = ref(false)
    const serverError = ref(null)

    const data = reactive({
      healthCheckResults: []
    })

    const deniedLogHealthQuestionAnswers = computed(() => {
      const deniedLog = JSON.parse(props?.visitor?.denied_log || "{}") || {}
      return Object.values(deniedLog?.health_screening_qna || {}) || []
    })

    const hasDeniedLogHealthQuestionAnswers = computed(() => {
      return deniedLogHealthQuestionAnswers?.value?.length > 0
    })

    /**
     * Computes the list of wrong health check answers.
     * @returns {Array} The list of wrong health check answers.
     */
    const wrongHealthCheckAnswers = computed(() => {
      if (hasDeniedLogHealthQuestionAnswers?.value) {
        return (
          deniedLogHealthQuestionAnswers?.value
            ?.filter(
              (item) =>
                item?.question?.toString()?.length > 0 &&
                item?.is_correct === false
            )
            ?.map((item) => {
              return {
                question: item?.question,
                visitorAnswer: Number(item?.answer)
              }
            }) || []
        )
      }

      return (
        data?.healthCheckResults
          ?.filter(
            (result) =>
              result?.question?.toString()?.length > 0 ||
              result?.spanishQuestion?.toString()?.length > 0
          )
          ?.filter(
            (result) =>
              result?.visitorAnswer !== null &&
              Number(result?.visitorAnswer) !== Number(result?.correctAnswer)
          ) || []
      )
    })

    /**
     * Determines whether there are wrong answers.
     * @returns {Boolean} True if there are wrong answers, otherwise false.
     */
    const hasWrongAnswers = computed(() => {
      return wrongHealthCheckAnswers?.value?.length > 0
    })

    /**
     * Determines whether to show allow and deny actions.
     * @returns {Boolean} True if in visitor denied flow and visitor recently signed in, otherwise false.
     */
    const showAllowAndDenyActions = computed(() => {
      return props.isInVisitorDeniedFlow && props.isVisitorRecentlySignedIn
    })

    /**
     * Determines whether to show cancel and proceed actions.
     * @returns {Boolean} True if in visitor denied flow and visitor not recently signed in, otherwise false.
     */
    const showCancelAndProceedActions = computed(() => {
      return props.isInVisitorDeniedFlow && !props.isVisitorRecentlySignedIn
    })

    const fetchResults = async () => {
      try {
        isLoading.value = true
        data.healthCheckResults = await store.dispatch(
          "visitorManage/getHealthCheckResults",
          {
            visitorId: props.visitor.visitor_id,
            visitId: props.visitor.visit_id
          }
        )
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    onMounted(async () => {
      if (hasDeniedLogHealthQuestionAnswers?.value) {
        return
      }
      await fetchResults()
    })

    const onAllow = () => {
      props.allow()
    }

    const onDeny = () => {
      props.deny()
      emit("cancel")
    }

    const onProceed = () => {
      props.proceed()
      setTimeout(() => {
        emit("cancel")
      })
    }

    const onCancel = () => {
      props.cancel()
      setTimeout(() => {
        emit("cancel")
      })
    }

    return {
      isLoading,
      wrongHealthCheckAnswers,
      hasWrongAnswers,
      serverError,
      showAllowAndDenyActions,
      showCancelAndProceedActions,
      onAllow,
      onDeny,
      onProceed,
      onCancel,
      visitorConstants
    }
  }
}
</script>

<style scoped>
.-mt-1 {
  margin-top: -1rem;
}

.font-bold {
  font-weight: bold;
}
</style>
