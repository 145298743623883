import visitorConstants from "@/constants/visitorConstants"
import axios from "axios"
import moment from "moment-timezone"
import { configure, configure6, visitorApiV1NoCache } from "./axiousWithCache"
import store from "../index"
import localforage from "localforage"

const state = {
  schools: null,
  activeSchool: null,
  activeSchoolModules: [],
  schoolDomains: []
}

const getters = {
  schools(state) {
    return state.schools
  },
  activeSchool(state) {
    return state.activeSchool
  },
  activeSchoolModules(state) {
    return state.activeSchoolModules.filter(
      (school) => school.pivot && school.pivot.status
    )
  },
  availableSchoolModules(state) {
    return state.activeSchoolModules
      ? state.activeSchoolModules.map((module) => module.name)
      : []
  },
  activeModulesNames(state, getters) {
    return getters.activeSchoolModules
      ? getters.activeSchoolModules.map((el) => el.name)
      : []
  },
  schoolDomains(state) {
    return state.schoolDomains
  },
  isActiveAppoinmentModule(state, getters) {
    return getters.activeModulesNames.includes("Appointmentpass")
  },
  activeAppoinmentModuleOptions(state, getters) {
    const apptModule = getters.activeSchoolModules
      ? getters.activeSchoolModules.filter(
          (el) => el.name === "Appointmentpass"
        )
      : []
    return apptModule[0] ? JSON.parse(apptModule[0].pivot.option_json) : null
  },
  activeKioskModuleOptions(state, getters) {
    const kioskModule = getters.activeSchoolModules
      ? getters.activeSchoolModules.filter((el) => el.name === "Kiosk")
      : []
    return kioskModule[0] ? JSON.parse(kioskModule[0].pivot.option_json) : null
  },
  tardyManagementOptions(state, getters) {
    const tardyModule = getters.activeSchoolModules
      ? getters.activeSchoolModules.filter(
          (el) => el.name === "Tardy Management"
        )
      : []
    return tardyModule[0] ? JSON.parse(tardyModule[0].pivot.option_json) : null
  },
  isCurrentUserHasAccessToTardyManagment(
    state,
    getters,
    rootState,
    rootGetters
  ) {
    return Boolean(
      rootGetters["authentication/getUserRole"] === "admin" ||
        (getters.tardyManagementOptions?.usage["within-ehp"] &&
          (getters.tardyManagementOptions?.["allow-teacher-staff"] ||
            (getters.tardyManagementOptions?.["allow-selected-users-only"] &&
              rootGetters["authentication/user"]?.allow_tardy)))
    )
  },
  isActiveAutoCheckInModule(state, getters) {
    return getters.activeModulesNames.includes("Auto Check-In")
  },
  isActiveKioskModule(state, getters) {
    return getters.activeModulesNames.includes("Kiosk")
  },
  isActiveTardyModule(state, getters) {
    return getters.activeModulesNames.includes("Tardy Management")
  },
  activeSchoolHasAppoitmentPasses(stat, getters) {
    const appointmentPass = getters.activeSchoolModules.find(
      (module) => module.name === "Appointmentpass"
    )
    if (!appointmentPass) {
      return false
    }
    const appointmentPassOptions = JSON.parse(appointmentPass.pivot.option_json)
    const currentUser = store.getters["authentication/user"]
    return (
      appointmentPassOptions[currentUser.role.name] ||
      appointmentPassOptions.location
    )
  },
  showStudentPhotos(state) {
    return state.activeSchool.show_photos
  },
  activeSchoolAuthProviders(state) {
    const providers = state.activeSchool.auth_providers

    if (Array.isArray(providers)) {
      return providers
    }

    if (typeof providers === "string" || providers instanceof String) {
      return JSON.parse(providers)
    }

    return []
  },
  visitorModule(_state, getters) {
    if (!getters.activeSchoolModules) {
      return null
    }
    const visitorModule = getters.activeSchoolModules.find(
      (module) => module.name === visitorConstants.MODULE
    )
    if (!visitorModule) {
      return null
    }
    return visitorModule
  },
  visitorModuleOption(_state, getters) {
    if (!getters.visitorModule) {
      return null
    }
    return JSON.parse(getters?.visitorModule?.pivot?.option_json || "{}")
  },
  isVisitorModuleActive(_state, getters) {
    const option = getters.visitorModule
    if (!option) {
      return false
    }
    return Number(option.status) === 1
  },
  activeSchoolTimeZone: (_state, getters) => {
    return getters?.activeSchool?.timezone || moment.tz.guess()
  }
}

const mutations = {
  SET_SCHOOLS(state, schoolsCollection) {
    state.schools = schoolsCollection
  },
  SET_ACTIVE_SCHOOL(state, school) {
    state.activeSchool = school
    if (school.active_domains) {
      localStorage.setItem("school_domains", school.active_domains)
    }
    moment.tz.setDefault(school.timezone)
  },
  SET_ACTIVE_SCHOOL_MODULES(state, modules) {
    state.activeSchoolModules = modules.filter((el) => Boolean(el.status))
  },
  SET_SCHOOL_DOMAINS(state, domains) {
    state.schoolDomains = domains
  }
}

const actions = {
  changeActiveSchool(context, school) {
    window.lf.clear()
    localforage.clear()
    axios.put(`/admin/schools/${school.id}`).then(() => {
      context.dispatch("getSchools", { clearCacheEntry: true })
      context.commit("SET_ACTIVE_SCHOOL", school)
    })
  },
  getSchools(context, config) {
    return new Promise((resolve, reject) => {
      configure6().then(async (api) => {
        await api
          .get(`/schools/active`, config)
          .then((response) => {
            const data = response.data
            if (data && data.data.length) {
              const activeSchool = data.data.filter(
                (school) => school.active_school
              )
              context.commit("SET_SCHOOLS", data.data)
              if (activeSchool.length) {
                context.commit("SET_ACTIVE_SCHOOL", activeSchool[0])
              }
            }
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    })
  },
  getActiveModules(context, config) {
    return new Promise((resolve, reject) => {
      configure().then(async (api) => {
        const response = await api.get("/admin/modules/active", config)
        const data = response.data.data
        if (data) {
          context.commit("SET_ACTIVE_SCHOOL_MODULES", data)
          resolve(response)
        } else {
          reject()
        }
      })
    })
  },
  getSchoolDomains(context, schoolId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/schools/${schoolId}/domains`)
        .then((response) => {
          const data = response.data
          context.commit("SET_SCHOOL_DOMAINS", data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createVisitorSettingsForSchool() {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/settings/enable`, {
          status: 1
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
