<template>
  <CHeader
    class="p-0 p-lg-3 the-header-vue3 flex-column-reverse"
    role="banner"
    aria-label="Page Header"
  >
    <CHeaderNav
      aria-label="Breadcrumb"
      class="mx-auto c-breadcrumb-container"
    >
      <CNavItem />
    </CHeaderNav>

    <CHeaderNav
      class="py-2 px-2 px-lg-0 py-md-0 header-actions-container"
      aria-label="Header Actions"
      role="list"
    >
      <CNavItem class="me-auto">
        <div
          class="c-header-breadcrumb-title lh-normal"
          data-test-id="header-breadcrumb-title"
        >
          {{
            route.meta.studentName && userRole === "student"
              ? route.meta.studentName
              : route.name
          }}
          <HelpCenterButton
            v-if="helpButtonView && userRole !== 'student'"
            classes="ms-2"
            :content-key="helpButtonView"
          />
        </div>
      </CNavItem>
      <CNavItem
        v-if="userRole !== 'student'"
        class="me-2 me-sm-3 d-flex align-items-center"
      >
        <HelpCenterButton
          data-test-id="header-helpcenter-btn"
          content-key="header asist"
        />
      </CNavItem>

      <CNavItem v-if="isVisibleActionButtons">
        <HeaderActions></HeaderActions>
      </CNavItem>

      <CNavItem class="pe-1 c-d-legacy-none">
        <TheHeaderCreateButton
          v-if="userRole !== 'student'"
          :user-role="userRole"
        />
      </CNavItem>

      <CNavItem v-if="userRole === 'student'" class="py-2 px-2">
        <TheHeaderDigitalID />
      </CNavItem>

      <CNavItem v-if="notificationsVisible" class="py-2 px-2">
        <TheHeaderDropdownNotif data-test-id="header-notification-btn" />
      </CNavItem>
      <CNavItem
        v-if="userRole === 'student'"
        class="me-2 me-sm-3 d-flex align-items-center"
        data-test-id="student-header-helpcenter-btn"
      >
        <HelpCenterButton width="17" height="17" content-key="student_header" />
      </CNavItem>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import { reactive, computed, watch, onMounted } from "vue"
import { useStore } from "vuex"
import { isV3ComponentVisible } from "@/helpers/featureFlags.js"
import { useRoute } from "vue-router"
import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif.vue"
import TheHeaderCreateButton from "./TheHeaderCreateButton.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import HeaderActions from "./HeaderActions.vue"
import TheHeaderDigitalID from "./TheHeaderDigitalID.vue"

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownNotif,
    TheHeaderCreateButton,
    HelpCenterButton,
    HeaderActions,
    TheHeaderDigitalID
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const state = reactive({
      routeRequiredModule: null
    })

    const user = computed(() => store.getters["authentication/user"])

    const userRole = computed(() => store.getters["authentication/getUserRole"])

    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    const isVisibleActionButtons = computed(() => {
      return (
        isV3ComponentVisible("HeaderActions") ||
        (state.routeRequiredModule &&
          state.routeRequiredModule.includes("Tardy Management"))
      )
    })

    const helpButtonView = computed(() => {
      return route.meta.helpButtonView ? route.meta.helpButtonView : false
    })

    const notificationsVisible = computed(() => {
      return (
        (activeSchool.value.is_premium &&
          ["superadmin", "admin"].some((role) => role === userRole.value)) ||
        userRole.value === "student"
      )
    })

    onMounted(() => {
      if (userRole.value !== "student") {
        store.dispatch("authentication/getUserSettings")
      }
      state.routeRequiredModule = route.meta?.requiredModule
    })

    watch(
      () => route,
      (route) => {
        state.routeRequiredModule = route.meta?.requiredModule
      }
    )

    return {
      route,
      state,
      user,
      userRole,
      isVisibleActionButtons,
      helpButtonView,
      notificationsVisible
    }
  }
}
</script>
