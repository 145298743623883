import visitorConstants from "@/constants/visitorConstants"
import passHelpers from "@/helpers/index"
import { visitorApiV1NoCache } from "@/store/modules/axiousWithCache"
import moment from "moment-timezone"

const state = {
  matchType: "emergency", // can be offender or watchlist
  reasonLookups: [],
  reasons: null,
  destinations: null,
  visitors: {
    isLoading: false,
    data: [],
    pagination: {
      activePage: 1,
      total: 0,
      pages: 0,
      per_page: { label: "25 / Page", value: 25 }
    },
    updateVisitorLogs: false
  },
  reportFile: null,
  printer: {
    printerId:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_ID
      ) || "",
    printerName:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_NAME
      ) || "",
    printerBrand:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_BRAND
      ) || "",
    printerFingerprint:
      localStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_FINGERPRINT
      ) || ""
  }
}

const getters = {
  matchType: (state) => state?.matchType || "emergency",
  reasonLookups: (state) => state?.reasonLookups || [],
  reasons: (state) => state?.reasons || null,
  destinations: (state) => state?.destinations || null,
  visitors: (state) => state?.visitors || null,
  printer: (state) => state?.printer || null,
  hasNoPrinter: (state) => {
    const p = state?.printer
    return (
      !p ||
      !p.printerId?.toString().trim() ||
      !p.printerName?.toString().trim() ||
      !p.printerBrand?.toString().trim() ||
      !p.printerFingerprint?.toString().trim()
    )
  },
  reportFile: (state) => state?.reportFile || null,
  hasDymoPrinter: (state, getters) => {
    if (getters.hasNoPrinter) {
      return false
    }
    return (
      state?.printer?.printerBrand
        ?.toLowerCase()
        ?.includes(visitorConstants?.PRINTER_CONFIG?.DYMO?.BRAND) || false
    )
  }
}

const actions = {
  getReasonLookups(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/lookup/${visitorConstants.CHECK_IN_FORM.REASON_DEFAULTS}`)
        .then((response) => {
          const data = response?.data?.data || []
          context.commit("SET_REASON_LOOKUPS", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getReasons(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/reasons`)
        .then((response) => {
          const data = response?.data?.data || null
          context.commit("SET_REASONS", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDestinations(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/destinations`)
        .then((response) => {
          const data = response?.data?.data || null
          context.commit("SET_DESTINATIONS", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addVisitor(context, payload) {
    const body = {
      ...payload,
      school_id: context?.rootGetters?.["schools/activeSchool"]?.id || ""
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post("/visitor", body)
        .then((response) => {
          resolve(response?.data?.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkPreApprovedVisitor(context, payload) {
    const body = {
      first_name: payload.firstName,
      last_name: payload.lastName,
      date_of_birth: payload.dateOfBirth
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post("/check-pre-approved-visitor", body)
        .then((response) => {
          resolve(response?.data?.is_pre_approved || false)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkWatchlist(context, payload) {
    const params = {
      first_name: payload.firstName,
      last_name: payload.lastName,
      dob: payload.dateOfBirth
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/watchlist`, {
          params
        })
        .then((response) => {
          resolve(response?.data?.data?.results || [])
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkOffender(context, payload) {
    const params = {
      first_name: payload.firstName,
      last_name: payload.lastName,
      date_of_birth: payload.dateOfBirth
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/offenders`, {
          params
        })
        .then((response) => {
          resolve(response?.data?.data || [])
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkVisitorGuardian(context, payload) {
    const body = {
      school_id: context?.rootGetters?.["schools/activeSchool"]?.id || "",
      visitor_first_name: payload.firstName,
      visitor_last_name: payload.lastName,
      visitor_id: payload.visitorId,
      students: payload.students
    }
    if (payload.fromDeniedFlow) {
      delete body.visitor_id
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post("/student/validate", body)
        .then((response) => {
          const data = response?.data?.data || {}

          if (data?.error) {
            resolve({
              error: true,
              type: visitorConstants.CHECK_IN_FORM
                .CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES.INVALID_STUDENT_DETAILS,
              message:
                data?.error_message ||
                visitorConstants.CHECK_IN_FORM
                  .CHECK_VISITOR_GUARDIAN_RESPONSE_MESSAGES
                  .INVALID_STUDENT_DETAILS,
              data: null
            })
            return
          }

          if (data?.error === false && Array.isArray(data?.data)) {
            const transformedStudents = data?.data?.map((student) => ({
              firstName: student?.first_name,
              lastName: student?.last_name,
              studentId: student?.student_id
            }))
            resolve({
              error: false,
              type: visitorConstants.CHECK_IN_FORM
                .CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES.VALID_STUDENT_DETAILS,
              message:
                data?.message ||
                visitorConstants.CHECK_IN_FORM
                  .CHECK_VISITOR_GUARDIAN_RESPONSE_MESSAGES
                  .VALID_STUDENT_DETAILS,
              data: { students: transformedStudents }
            })
            return
          }

          if (Array.isArray(data)) {
            const transformedData = data?.map((item) => ({
              student: {
                studentId: item?.student?.student_id,
                firstName: item?.student?.first_name,
                lastName: item?.student?.last_name
              },
              guardians: item?.guardians
                ?.filter((guardian) => !!guardian)
                ?.map((guardian) => {
                  const [firstName, lastName, phoneNumber] = guardian.split("|")
                  return { firstName, lastName, phoneNumber }
                })
            }))
            resolve({
              error: false,
              type: visitorConstants.CHECK_IN_FORM
                .CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES
                .VALID_STUDENT_NON_GUARDIAN_DETAILS,
              message:
                visitorConstants.CHECK_IN_FORM
                  .CHECK_VISITOR_GUARDIAN_RESPONSE_MESSAGES
                  .VALID_STUDENT_NON_GUARDIAN_DETAILS,
              data: { students: transformedData }
            })
            return
          }

          resolve({
            error: false,
            type: visitorConstants.CHECK_IN_FORM
              .CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES.NON_MATCHING_RESPONSE,
            message:
              visitorConstants.CHECK_IN_FORM
                .CHECK_VISITOR_GUARDIAN_RESPONSE_MESSAGES.NON_MATCHING_RESPONSE,
            data: null
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchParent(context, payload) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/parent`, {
          params: {
            search: payload.search
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchVisitor(context, payload) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/search`, {
          params: {
            name: payload.search,
            device: "web",
            school_id: context?.rootGetters?.["schools/activeSchool"]?.id || ""
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getHealthCheckResults(context, payload) {
    const schoolId = context?.rootGetters?.["schools/activeSchool"]?.id || ""
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/check/health-screening`, {
          params: {
            school_id: schoolId,
            visit_id: payload.visitId
          }
        })
        .then((response) => {
          resolve(response?.data?.data || [])
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getVisitorList(context, payload) {
    context.commit("SET_VISITORS_LOADING", true)
    return new Promise((resolve, reject) => {
      const timeZone = passHelpers.getCurrentTimezoneName()

      const startDate =
        payload?.startDate ||
        passHelpers.currTzDate(
          moment().subtract(6, "days").format("YYYY-MM-DD"),
          "YYYY-MM-DD"
        )

      const utcStartDate = moment
        .tz(startDate, "YYYY-MM-DD", timeZone)
        .startOf("day")
        .utc()
        .format("YYYY-MM-DD")

      const endDate =
        payload?.endDate ||
        passHelpers.currTzDate(moment().format("YYYY-MM-DD"), "YYYY-MM-DD")

      const utcEndDate = moment
        .tz(endDate, "YYYY-MM-DD", timeZone)
        .endOf("day")
        .utc()
        .format("YYYY-MM-DD")

      const body = {
        page: Number(context?.state?.visitors?.pagination?.activePage || 1),
        per_page: Number(
          context?.state?.visitors?.pagination?.per_page?.value || 25
        ),
        start_date: utcStartDate,
        end_date: utcEndDate,
        school_id: context?.rootGetters?.["schools/activeSchool"]?.id || ""
      }

      if (payload?.visitorName) {
        body.visitor_name = payload.visitorName
      }

      if (payload?.status) {
        body.status = payload.status
      }

      if (payload?.reasonForVisit) {
        body.reason_for_visit = payload.reasonForVisit
      }

      if (payload?.reasonForVisitText) {
        body.reason_for_visit_text = payload.reasonForVisitText
      }

      if (payload?.destination) {
        body.destination = payload.destination
      }

      if (payload?.destinationId) {
        body.destination_id = payload.destinationId
      }

      visitorApiV1NoCache
        .get(`/visitor-logs`, {
          params: body
        })
        .then((response) => {
          const data = response?.data?.data || []
          const totalRecords = response?.data?.meta?.total || 0
          const records = data || []
          context.commit("SET_VISITORS_DATA", {
            totalRecords,
            records
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit("SET_VISITORS_LOADING", false)
        })
    })
  },
  getSignedInVisitorList(context, payload) {
    return new Promise((resolve, reject) => {
      const startDate = passHelpers.currTzDate(payload.startDate, "YYYY-MM-DD")
      const endDate = passHelpers.currTzDate(payload.endDate, "YYYY-MM-DD")
      const timeZone = passHelpers.getCurrentTimezoneName()
      const utcStartDate = moment
        .tz(startDate, "YYYY-MM-DD", timeZone)
        .startOf("day")
        .utc()
        .format("YYYY-MM-DD")
      const utcEndDate = moment
        .tz(endDate, "YYYY-MM-DD", timeZone)
        .endOf("day")
        .utc()
        .format("YYYY-MM-DD")
      const body = {
        page: payload.page,
        per_page: payload.perPage,
        start_date: utcStartDate,
        end_date: utcEndDate,
        school_id: context?.rootGetters?.["schools/activeSchool"]?.id || "",
        status: visitorConstants.CHECK_IN_FORM.FILTER_VISIT_STATUS_SIGNED_IN
      }

      visitorApiV1NoCache
        .get(`/visitor-logs`, {
          params: body
        })
        .then((response) => {
          const data = response?.data?.data || {}
          const totalCount = data?.total_records || 0
          const result = data?.records || []
          resolve({
            totalCount,
            result
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkOutVisitors(context, payload) {
    context.commit("SET_VISITORS_LOADING", true)
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .put("/check-out", payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          context.commit("SET_VISITORS_LOADING", false)
        })
    })
  },
  updateVisitor(context, payload) {
    const body = {
      ...payload,
      school_id: context?.rootGetters?.["schools/activeSchool"]?.id || ""
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .put("/visitor", body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendDeniedLog(_context, payload) {
    const body = {
      ...payload
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .put("/visitor-denied", body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  generateReport(context, payload) {
    const body = {
      school_id: context?.rootGetters?.["schools/activeSchool"]?.id || "",
      status: payload.status,
      start_date: payload.startDate,
      end_date: payload.endDate,
      type: payload.type,
      file_type: payload.fileType,
      time_zone: passHelpers.getCurrentTimezoneName(),
      isDestination: payload?.isDestination ? 1 : 0
    }
    if (payload?.emailAddress) {
      body.email = [payload.emailAddress]
    }
    if (payload?.reasonForVisit) {
      body.reason_for_visit = payload.reasonForVisit
    }
    if (payload?.reasonForVisitText) {
      body.reason_for_visit_text = payload.reasonForVisitText
    }
    if (payload?.destination) {
      body.destination = payload.destination
    }
    if (payload?.destinationId) {
      body.destination_id = payload.destinationId
    }
    if (payload?.addIsDestinationActive) {
      body.destination_active = payload?.isDestinationActive ? 1 : 0
    }
    if (payload?.addIsReasonActive) {
      body.reason_active = payload?.isReasonActive ? 1 : 0
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post("/reports", body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendAlert(context) {
    const ALERT_TYPE = {
      emergency: "EMERGENCY_ALERT",
      offender: "SEX_OFFENDER_ALERT",
      watchlist: "WATCHLIST_ALERT"
    }
    const type = context.state.matchType
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post("/send/alerts", {
          alert_type: ALERT_TYPE?.[type] || ALERT_TYPE.emergency,
          school_id: context?.rootGetters?.["schools/activeSchool"]?.id || "",
          time_zone: passHelpers.getCurrentTimezoneName()
        })
        .then((response) => {
          const data = response?.data?.data || {}
          const smsResponse = JSON.stringify(data?.smsAlertResponse)
            ?.toLowerCase()
            ?.trim()
          const isSmsContactListEmpty = smsResponse?.includes(
            visitorConstants.CHECK_IN_FORM.ALERT_RESPONSE_EMPTY_LIST
          )
          const emailResponse = JSON.stringify(data?.emailAlertResponse)
            ?.toLowerCase()
            ?.trim()
          const isEmailContactListEmpty = emailResponse?.includes(
            visitorConstants.CHECK_IN_FORM.ALERT_RESPONSE_EMPTY_LIST
          )
          if (isSmsContactListEmpty || isEmailContactListEmpty) {
            const messages = [
              visitorConstants.CHECK_IN_FORM
                .MESSAGE_WHEN_SMS_CONTACT_LIST_EMPTY,
              visitorConstants.CHECK_IN_FORM
                .MESSAGE_WHEN_EMAIL_CONTACT_LIST_EMPTY
            ]
            throw new Error(
              [isSmsContactListEmpty, isEmailContactListEmpty]
                .filter((item) => item)
                .map((item, index) => messages[index])
                .join("\n\n")
            )
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendApp(context, params) {
    const payload = {
      ...params,
      environment: process.env.NODE_ENV,
      mode: "vms-web",
      title: "Photo request",
      description: "Photo request from Web"
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post("/send/notification", payload)
        .then((response) => {
          if (response?.data?.data?.message === "Unable to send notification") {
            const errorExists =
              response?.data?.data?.error?.toString()?.trim()?.length > 0
            reject(
              new Error(
                `Shucks! We were unable to send the notification to mobile application ${
                  errorExists ? `due to: ${response?.data?.data?.error}` : ""
                }`
              )
            )
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendFcmToken(context, { token, userId }) {
    const payload = {
      token,
      user_id: userId,
      device_type: "vms-web",
      environment: process.env.NODE_ENV
    }
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post("/device", payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (
            error?.response?.data?.errors?.token
              ?.toString()
              ?.trim()
              ?.toLowerCase()
              ?.includes("the token has already been taken.")
          ) {
            resolve(error.response)
          } else {
            reject(error)
          }
        })
    })
  },
  getPrinters(context) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .get(`/printers`, {
          params: {
            fingerprint: context?.state?.printer?.printerFingerprint || ""
          }
        })
        .then((response) => {
          const data = response?.data?.data || []
          if (data?.length > 0) {
            context.commit("SET_PRINTER", data?.[0] || {})
          } else {
            context.commit("SET_PRINTER", {})
          }
          resolve(data)
        })
        .catch((err) => {
          context.commit("SET_PRINTER", {})
          reject(err)
        })
    })
  },
  deletePrinter(context, id) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .delete(`/printers`, {
          data: {
            data: { id }
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePrinter(context, payload) {
    return new Promise((resolve, reject) => {
      visitorApiV1NoCache
        .post(`/printers`, {
          data: {
            ...payload
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_MATCH_TYPE(state, type) {
    state.matchType = type || "emergency"
  },
  SET_REASON_LOOKUPS(state, data) {
    state.reasonLookups = data || []
  },
  SET_REASONS(state, data) {
    state.reasons = data || null
  },
  SET_DESTINATIONS(state, data) {
    state.destinations = data || null
  },
  SET_VISITORS_LOADING(state, isLoading) {
    state.visitors.isLoading = isLoading
  },
  SET_VISITORS_DATA(state, { totalRecords = 0, records = [] }) {
    state.visitors.data = records
    state.visitors.pagination.total = Number(totalRecords)
    state.visitors.pagination.pages = Math.ceil(
      Number(totalRecords) / Number(state.visitors.pagination.per_page.value)
    )
  },
  SET_VISITORS_PAGINATION_PER_PAGE(state, perPage) {
    perPage.label = perPage?.label || "25 / Page"
    perPage.value = perPage?.value || 25
    state.visitors.pagination.per_page = {
      label: perPage.label,
      value: Number(perPage.value)
    }
  },
  SET_VISITORS_PAGINATION_ACTIVE_PAGE(state, activePage) {
    state.visitors.pagination.activePage = Number(activePage) || 1
  },
  SET_UPDATE_VISITOR_LOGS(state, flag) {
    state.visitors.updateVisitorLogs = flag
  },
  SET_PRINTER(state, printer) {
    state.printer = {
      printerId: printer?.id || "",
      printerName: printer?.printer_name || "",
      printerBrand: printer?.printer_brand || "",
      printerFingerprint:
        localStorage.getItem(
          visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.PRINTER_FINGERPRINT
        ) || ""
    }
  },
  SET_CHECKIN_REPORT_FILE(state, file) {
    state.reportFile = file
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
