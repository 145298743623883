<template>
  <i
    class="c-sidebar-nav-icon c-sidebar-nav-icon-margin"
    :class="name"
    :aria-label="
      pageName ? `Navigation Icon for ${pageName}` : 'Navigation icon'
    "
    role="img"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    pageName: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>
.c-sidebar-nav-icon-margin {
  margin-top: -0.45rem;
}
</style>
