<template>
  <div class="visitor-entry-log">
    <template v-if="deniedLogMessages.length > 0">
      <div class="denied-log">
        <p class="log-title">Initial Visitor Denied Log</p>
        <p class="log-description">
          This log records the first time the visitor was denied access, whether
          from the web app or mobile application.
        </p>
        <div
          v-for="(message, index) in deniedLogMessages"
          :key="message.key"
          class="log-entry"
        >
          <div class="log-key">
            {{ `${index + 1}. ` + message.key }}
          </div>
          <div class="log-message">{{ message.message }}</div>
        </div>
      </div>
    </template>

    <div v-if="exceptionMessage.length > 0" class="exception-message">
      <p class="log-title">Visitor Most Recent Log</p>
      <p class="log-description">
        This log reflects the most recent visitor exception message, whether a
        denial, approval or an override denial or approval.
      </p>
      <div class="log-message">{{ exceptionMessage }}</div>
    </div>
  </div>
</template>

<script>
import visitorConstants from "@/constants/visitorConstants"
import { computed } from "vue"

export default {
  name: "VisitorEntryLog",
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const deniedLogMessages = computed(() => {
      const parsedLog = JSON.parse(props?.visitor?.denied_log) || {}
      if (Object.keys(parsedLog).length === 0) {
        return []
      }
      const messages = []
      const logKeys = Object.keys(visitorConstants.DENIED_LOG_MESSAGE_KEYS)
      logKeys.forEach((key) => {
        if (
          parsedLog?.[visitorConstants.DENIED_LOG_REASON_KEYS[key]] === true &&
          parsedLog?.[visitorConstants.DENIED_LOG_MESSAGE_KEYS[key]]
            ?.toString()
            ?.trim()?.length > 0
        ) {
          messages.push({
            key: visitorConstants.DENIED_FLOW_KEYS[key],
            message: parsedLog[visitorConstants.DENIED_LOG_MESSAGE_KEYS[key]]
          })
        }
      })
      return messages
    })

    const exceptionMessage = computed(
      () => props?.visitor?.signin_exception_message || ""
    )

    return {
      deniedLogMessages,
      exceptionMessage
    }
  }
}
</script>
