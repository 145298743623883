import axios from "axios"
import { api } from "./axiousWithCache"

const state = {
  favorites: []
}

const getters = {
  fromFavorites(state) {
    return state.favorites
  }
}

const mutations = {
  SET_FAVORITES(state, favoritesCollection) {
    state.favorites = favoritesCollection
  },
  UPDATE_FAVORITE_UNAVAILABILITY(state, favObj) {
    const newCollection = state.favorites.map((el) => {
      if (el.favorable_id === favObj.id) {
        el.favorable.active_unavailability = favObj.unavailability
          ? {
              comment: favObj.unavailability.comment,
              to_date: favObj.unavailability.to_date
            }
          : null
      }
      return el
    })
    state.fromFavorites = newCollection
  }
}

const actions = {
  getFavorites(context, config) {
    return new Promise((resolve, reject) => {
      api
        .get("/users/favorites", config)
        .then((response) => {
          const data = response.data.data
          if (data && Array.isArray(data)) {
            context.commit("SET_FAVORITES", data)
            resolve(response)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  toggleFavorite(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/rooms/favorites`, {
          favorable_id: data.id
        })
        .then((response) => {
          context.dispatch("getFavorites", { clearCacheEntry: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addFavorite(context, elem) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/users/favorites`, elem)
        .then((response) => {
          context.dispatch("getFavorites", { clearCacheEntry: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteFavorite(context, elem) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/users/favorites/${elem}`)
        .then((response) => {
          context.dispatch("getFavorites", { clearCacheEntry: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateFavoritePosition(context, elem) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/favorites/${elem.id}`, {
          position: elem.position
        })
        .then((response) => {
          context.dispatch("getFavorites", { clearCacheEntry: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateFavoritePositionBulk(context, elem) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/favorites/bulk-reorder`, {
          favorites: elem
        })
        .then((response) => {
          context.dispatch("getFavorites", { clearCacheEntry: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateFavoriteBulk(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/favorites/bulk`, {
          favorites: data.elements,
          section: data.section,
          global: data.global
        })
        .then((response) => {
          context.dispatch("getFavorites", { clearCacheEntry: true })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
